import { styled } from '@mui/material/styles'
import { Button } from '@components/UI'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

export const AddressBookWrapper = styled('div', {
  name: 'AddressBook',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(8, 4),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8, 16),
  },
}))

export const AddressBookTitle = styled(Typography, {
  name: 'AddressBook',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.text.dark,
}))

export const NewAddressTitle = styled('div', {
  name: 'NewAddress',
  slot: 'Title',
})(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.dark,
  textTransform: 'uppercase',
}))

export const AddressBookContent = styled('div', {
  name: 'AddressBook',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: 'auto',
    minWidth: 342,
  },
}))

export const AddressBoxWrapper = styled('div', {
  name: 'AddressBox',
  slot: 'ContactWrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: theme.spacing(4),
  backgroundColor: theme.palette.color.white.main,
  padding: theme.spacing(4, 4, 3, 4),
  border: `solid 1px ${theme.palette.color.grey.light}`,

  [theme.breakpoints.up('sm')]: {
    height: '100%',
  },
}))

export const AddressFormWrapper = styled('div', {
  name: 'AddressForm',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.color.white.main,
  border: `1px solid ${theme.palette.color.grey.light}`,
  rowGap: theme.spacing(6),
  padding: theme.spacing(6, 4),

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
  },
}))

export const AddressLineBreak = styled('div', {
  name: 'AddressLine',
  slot: 'Break',
})(({ theme }) => ({
  height: 1,
  alignSelf: 'stretch',
  flexGrow: 0,
  borderTop: `solid 0.1em ${theme.palette.color.grey.light}`,
}))

export const AddressButtonWrapper = styled('div', {
  name: 'AddressButton',
  slot: 'Wrapper',
})(() => ({
  maxWidth: 300,
}))

export const AddressCustomerName = styled('span', {
  name: 'CustomerName',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.dark,
  lineHeight: 1.5,
  fontWeight: 600,
}))

export const UserAddressContainer = styled('div', {
  name: 'UserAdress',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(6),
  alignItems: 'stretch',
}))

export const UserAddressBox = styled('span', {
  name: 'UserAddress',
  slot: 'Wrapper',
})({
  display: 'flex',
  flexDirection: 'column',
})

export const WrapperRow = styled('span', {
  name: 'RowElement',
  slot: 'WrapperRow',
})({
  display: 'flex',
  flexDirection: 'row',
})

export const AddressBookNameWrapper = styled('span', {
  name: 'AddressBook',
  slot: 'NameWrapper',
})(() => ({
  lineHeight: 1,
}))

export const UserAddressWrapper = styled('div', {
  name: 'UserAddress',
  slot: 'Wrapper',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

export const RemoveButtonWrapper = styled('div', {
  name: 'NameButton',
  slot: 'Wrapper',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const AddAddressFormButtonContainer = styled('div', {
  name: 'AddAddressForm',
  slot: 'ButtonContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),

  '& > button': {
    flexGrow: 1,
  },

  [theme.breakpoints.up('sm')]: {
    '& > button': {
      flexGrow: 'initial',
    },
  },
}))

export const StyledAddAddressButton = styled(Button, {
  name: 'AddAddressForm',
  slot: 'AddAddressButton',
})(() => ({
  '&:disabled': {
    opacity: 1,
    pointerEvents: 'auto',
  },
}))

export const AddAddressButtonContainer = styled('div', {
  name: 'AddAddress',
  slot: 'ButtonContainer',
})(({ theme }) => ({
  '& > button': {
    width: 197,
    fontSize: 14,
    padding: theme.spacing(4),
  },
}))

export const StyledSecondaryText = styled('span', {
  name: 'StyledText',
  slot: 'TextField',
})(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.5,
  color: theme.palette.text.dark,
}))

export const RemoveAddressModalContainer = styled(Box, {
  name: 'StyledBoxContainer',
  slot: 'Box',
})(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: '40%',
  [theme.breakpoints.up('sm')]: {
    left: '50%',
  },
}))

export const AddressDetails = styled('div', {
  name: 'AddressBox',
  slot: 'Details',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}))

export const StyledAddAddressBookButton = styled(Button, {
  name: 'AddAddressForm',
  slot: 'AddAddressButton',
})(() => ({
  minWidth: 240,
  '&:disabled': {
    opacity: 1,
    pointerEvents: 'auto',
  },
}))

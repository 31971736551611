import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ServerProduct, ProductType } from '../../types/product'
import { IGetProductDetailsResult, productApi } from './query'
import {
  getCurrentProductItemFromProductData,
  normalizedProductType,
} from '../../utils/product'
import { ContactLensesData } from 'src/types/prescription'

export const toggleAddToCartButton = (productType: ProductType): boolean => {
  switch (productType) {
    case 'contact-lenses':
      return false
    default:
      return true
  }
}

export type ProductState = {
  productType: ProductType | null
  contactLensesData?: ContactLensesData | null
  fieldsHaveErrors?: boolean
  addContactLensesToCartError?: boolean
  addToCartEnabled?: boolean
  moCoOrderedIds: string[]
}

export type ProductApiState = Partial<ServerProduct>

const initialState: ProductState = {
  productType: null,
  contactLensesData: {
    left: {},
    right: {},
  },
  fieldsHaveErrors: false,
  addToCartEnabled: false,
  addContactLensesToCartError: false,
  moCoOrderedIds: [],
}

export const {
  actions: {
    updateContactLensData,
    toggleAddToCart,
    toggleAddContactLensesToCartError,
    setMocoOrderedIds,
    toggleAFieldsHaveErrors,
  },
  reducer,
} = createSlice({
  name: 'product',
  initialState,
  reducers: {
    updateContactLensData: (
      state: ProductState,
      action: PayloadAction<ContactLensesData>
    ) => {
      state.contactLensesData = action.payload
    },
    toggleAFieldsHaveErrors: (
      state: ProductState,
      action: PayloadAction<boolean>
    ) => {
      state.fieldsHaveErrors = action.payload
    },
    toggleAddToCart: (state: ProductState, action: PayloadAction<boolean>) => {
      state.addToCartEnabled = action.payload
    },
    toggleAddContactLensesToCartError: (
      state: ProductState,
      action: PayloadAction<boolean>
    ) => {
      state.addContactLensesToCartError = action.payload
    },
    setMocoOrderedIds: (
      state: ProductState,
      action: PayloadAction<string[]>
    ) => {
      state.moCoOrderedIds = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productApi.endpoints.getProductDetails.matchFulfilled,
      (state, { payload }: PayloadAction<IGetProductDetailsResult>) => {
        const productData = payload
        const currentProduct =
          productData || payload
            ? getCurrentProductItemFromProductData(
                productData?.product.partNumber!,
                productData.product
              )
            : null

        const productType = currentProduct?.productAttributes['PRODUCT_TYPE']
          ? normalizedProductType(
              currentProduct?.productAttributes['PRODUCT_TYPE']
            )
          : null

        state.productType = productType
        state.addToCartEnabled = toggleAddToCartButton(productType)
      }
    )
  },
})

export default reducer

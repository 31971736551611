import React from 'react'
import { ITeaserCallToAction } from '../../../../types/cmsPlacement/LXTeaser'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { Button } from '../../../UI'
import { LinkAsButton } from '../../../UI'
import { GenericButtonProps } from '../../../UI/Button/Button'
import { useExternalsActions } from '../../../../hooks/useExternalsAction'
import { pascalCase } from '@utils/common'

interface IProps {
  actionSettings: ITeaserCallToAction
  dataElementId: string
}

export const getStyledCta = (style: ITeaserCallToAction['style']) => {
  const getValues = style.split('-')
  const variant = (getValues[2] || 'primary') as GenericButtonProps['variant']
  const fillType = (getValues[1] || 'fill') as GenericButtonProps['fillType']

  return {
    variant,
    fillType,
  }
}

const CmsCta: React.FC<IProps> = ({ actionSettings, dataElementId }) => {
  const { basePath } = useStoreIdentity()

  const actions = useExternalsActions()

  const ctaEnabled = actionSettings?.callToActionEnabled || false
  const text = actionSettings?.callToActionText || ''
  const formattedUrl = actionSettings?.target?.formattedUrl || ''
  const externalUrl = actionSettings?.target?.url || ''
  const style = actionSettings?.style || ''
  const { fillType, variant } = getStyledCta(style)
  const isExternal = ['CMExternalLink'].includes(actionSettings?.target?.type)
  const dataDescription =
    actionSettings?.target?.type === 'CMProductTeaser'
      ? actionSettings.target.teaserTitle
      : actionSettings.target.title

  const actionHash = actionSettings?.callToActionHash || ''
  const enabledHash = !!actionSettings?.callToActionHash
  const link = isExternal
    ? `${externalUrl}`
    : `${basePath}${formattedUrl ?? ''}`

  if (!ctaEnabled) return null

  const scrollHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault()
    document?.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
  }
  if (actionSettings.target.type === 'Action') {
    const action = actions[actionSettings.target.idAction]

    return (
      <Button variant={variant} fillType={fillType} onClick={action}>
        {text}
      </Button>
    )
  }

  return (
    <>
      {enabledHash ? (
        <Button
          variant={variant}
          fillType={fillType}
          onClick={(e) => scrollHandler(e, actionHash)}
        >
          {text}
        </Button>
      ) : (
        <LinkAsButton
          data-description={pascalCase(dataDescription)}
          dataElementId={dataElementId}
          external={isExternal}
          to={link}
          variant={variant}
          fillType={fillType}
        >
          {text}
        </LinkAsButton>
      )}
    </>
  )
}

export default CmsCta

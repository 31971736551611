import config from '@configs/config.base'
import {
  ACCOUNT_CHILDREN,
  CART,
  PRESCRIPTION_VERIFICATION,
} from '../../constants/routes'
import {
  ORDER_ITEM_STATUS,
  ORDER_STATUSES,
  PENDING_PRESCRIPTION_STATUSES,
  SHIPPED_STATUSES,
} from '../../constants/order'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  UnicodeArrowLeftIcon,
} from '@components/UI/Icons'
import {
  IAccountActiveComponent,
  generateAccountPagePath,
} from '../Account/Account'
import {
  OrderDetailsHeader,
  OrderHistoryContainerWrapper,
  OrderHistoryEmptyContainer,
  OrderHistoryEmpty,
  OrderHistoryClickBelow,
  OrderHistoryEmptyCTAs,
  OrderHistoryEmptyWrapper,
  OrderHistoryHeader,
  OrderHistoryImage,
  OrderHistoryItem,
  OrderHistoryItemInfo,
  OrderHistoryItemSeparator,
  OrderHistoryItemTitle,
  OrderHistoryItemTitleWrapper,
  OrderHistoryPrescriptionLabel,
  OrderHistoryShippedLabel,
  OrderHistorySortByCTA,
  OrderHistorySortByWrapper,
  OrderHistoryStatusContainer,
  OrderHistoryStyledAnchorCTA,
  OrderHistoryStyledButtonCTA,
  OrderHistoryStyledLinkCTA,
  OrderHistoryTitle,
  OrderHistoryWrapper,
  OrdersMadeIn,
  ProductImageWrapper,
  StyledBackToOrderHistoryWrapper,
  StyledBadge,
  OrderHistorySortByContent,
  OrderHistorySortByContainer,
  OrderHistoryPopup,
  LoaderWrapper,
} from './OrderHistory.style'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import DateService from '../../services/DateService'
import { Order } from '../../types/order'
import OrderDetails from './components/OrderDetails'
import OrderService from '../../foundation/apis/transaction/order.service'
import {
  LinkAsButton,
  StyledRadioGroup,
  StyledFormControlLabel,
  StyledRadio,
  StyledLoader,
} from '@components/UI'
import cartService from '../../foundation/apis/transaction/cart.service'
import { sendAccountInformationEvent } from '../../foundation/analytics/tealium/lib'
import useBreakpoints from '../../hooks/useBreakpoints'
import useRouteQuery from '../../foundation/hooks/useRouteQuery'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import { userIdSelector } from '../../redux/selectors/user'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import { formatPathWithBase } from '@utils/url'
import { getOrderReturnedStatus } from '@utils/order'
import {
  PLP_LINK_CONTACT_LENSES,
  PLP_LINK_SUNGLASSES,
} from '../../constants/ui'
import FormattedPriceDisplay from '@components/formatted-price-display'
import { useAnalyticsData } from 'src/foundation/analytics/hooks/useAnalyticsData'

/**
 * `@name OrderHistory`
 *
 * Component that is rendered at `/account/order-history` route. Routing is managed by `Account` component.
 */
const OrderHistory: IAccountActiveComponent = ({ onSetAccountNavDisplay }) => {
  const { t } = useTranslation()
  const routeQuery = useRouteQuery()
  const { basePath, langCode } = useStoreIdentity()
  const userId = useSelector(userIdSelector)
  const analyticsData = useAnalyticsData('profile')
  const { isMobile, isDesktop } = useBreakpoints()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [orders, setOrders] = useState<Order[]>([])
  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const sortByDivRef = useRef<HTMLDivElement>(null)
  const [selectedSortOption, setSelectedSortOption] = useState<string>('All')

  const orderIdToShowDetails = useMemo<string>(
    () => routeQuery.get('orderId') || '',
    [routeQuery]
  )

  const url404 = config.PUBLIC_URL + '/images/common/404.svg'

  const fetchAndSetOrders = async (paginationPageNumber: number) => {
    try {
      setLoading(true)
      const dateNow = new Date()
      let dateParam = dateNow

      switch (selectedSortOption) {
        case 'Last6Months':
          dateParam.setMonth(dateNow.getMonth() - 6)
          break
        case 'LastYear':
          dateParam.setMonth(dateNow.getMonth() - 12)
          break
        default:
          dateParam.setFullYear(dateNow.getFullYear() - 100)
          break
      }
      const orderHistoryPayload =
        selectedSortOption === 'All'
          ? {
              pageNumber: paginationPageNumber,
              userId,
              pageSize: 100,
            }
          : {
              pageNumber: paginationPageNumber,
              userId,
              pageSize: 100,
              dateBefore: DateService.format(dateParam, 'yyyy-MM-dd'),
            }

      const response = await OrderService.getOrderHistory(
        orderHistoryPayload.pageNumber,
        orderHistoryPayload.userId,
        orderHistoryPayload.pageSize,
        orderHistoryPayload.dateBefore
      )

      setOrders(response.Order || [])
    } catch (error) {
      setOrders([])
    } finally {
      setLoading(false)
    }
  }

  const onOrderAgain = (orderId: string, isRX: string) => {
    if (orderId) {
      cartService
        .copyOrder({
          copyOrderItemId: '*',
          fromOrderId: orderId,
          fromOrderId_0: orderId,
          rxCopyEnabled: isRX,
          newUserId: userId,
        })
        .then(() => {
          navigate(generatePath(`/:country/${CART}`, { country: langCode }))
        })
    }
  }

  const onSortingSubmit = (sortValue: string) => {
    if (isEqual(sortValue, selectedSortOption)) {
      return
    }

    setSelectedSortOption(sortValue)
    setFilterMenuOpen(false)
  }

  useEffect(() => {
    sendAccountInformationEvent({
      common: analyticsData,
      Page_Section2: 'OrderHistory',
    })
    fetchAndSetOrders(1)
  }, [selectedSortOption])

  useEffect(() => {
    onSetAccountNavDisplay(isDesktop || !orderIdToShowDetails)
  }, [isDesktop, orderIdToShowDetails])

  if (isLoading) {
    return (
      <LoaderWrapper>
        <StyledLoader size={40} />
      </LoaderWrapper>
    )
  }

  return orderIdToShowDetails ? (
    <OrderHistoryWrapper>
      <StyledBackToOrderHistoryWrapper
        to={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY)}
      >
        <UnicodeArrowLeftIcon />
        {t('Order.BackToOrderHistory')}
      </StyledBackToOrderHistoryWrapper>
      <OrderDetailsHeader>{t('Order.OrderDetails')}</OrderDetailsHeader>
      <OrderDetails orderId={orderIdToShowDetails} />
    </OrderHistoryWrapper>
  ) : (
    <OrderHistoryWrapper>
      <OrderHistoryHeader>
        <OrderHistoryTitle>{t('Order.OrderHistory')}</OrderHistoryTitle>
        {orders.length && orders.length > 0 && (
          <OrderHistorySortByWrapper ref={containerRef}>
            <OrdersMadeIn>
              {selectedSortOption !== 'All'
                ? orders.length === 1
                  ? t('AccountLinksGridView.OrdersCountSingle', {
                      count: orders.length,
                    })
                  : t('AccountLinksGridView.OrdersCountPlural', {
                      count: orders.length,
                    })
                : null}
            </OrdersMadeIn>

            <OrderHistorySortByCTA
              labelText={t(`Order.${selectedSortOption}`).toUpperCase()}
              variant={'secondary'}
              endIcon={filterMenuOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
              onClick={() => setFilterMenuOpen((v) => !v)}
            />
            <div ref={sortByDivRef}>
              {filterMenuOpen && (
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchEnd"
                  onClickAway={(event) => {
                    event.preventDefault()
                    setFilterMenuOpen(false)
                  }}
                >
                  <OrderHistoryPopup>
                    <OrderHistorySortByContent isVisible={true}>
                      <OrderHistorySortByContainer>
                        <StyledRadioGroup defaultValue={selectedSortOption}>
                          <StyledFormControlLabel
                            key={'Last6Months'}
                            value={'Last6Months'}
                            control={<StyledRadio />}
                            label={t('Order.Last6Months')}
                            onChange={() => onSortingSubmit('Last6Months')}
                          />
                          <StyledFormControlLabel
                            key={'LastYear'}
                            value={'LastYear'}
                            control={<StyledRadio />}
                            label={t('Order.LastYear')}
                            onChange={() => onSortingSubmit('LastYear')}
                          />
                          <StyledFormControlLabel
                            key={'All'}
                            value={'All'}
                            control={<StyledRadio />}
                            label={t('Order.All')}
                            onChange={() => onSortingSubmit('All')}
                          />
                        </StyledRadioGroup>
                      </OrderHistorySortByContainer>
                    </OrderHistorySortByContent>
                  </OrderHistoryPopup>
                </ClickAwayListener>
              )}
            </div>
          </OrderHistorySortByWrapper>
        )}
      </OrderHistoryHeader>

      {!isLoading && !orders.length ? (
        <OrderHistoryEmptyWrapper>
          <OrderHistoryEmptyContainer>
            <OrderHistoryEmpty>{t('Order.NoRecord')}</OrderHistoryEmpty>
            <OrderHistoryClickBelow>
              {t('Order.ClickBelow')}
            </OrderHistoryClickBelow>
          </OrderHistoryEmptyContainer>
          <OrderHistoryEmptyCTAs>
            <OrderHistoryStyledButtonCTA
              fullWidth
              to={`${basePath}/${PLP_LINK_SUNGLASSES}`}
            >
              {t('Order.Shop.ShopSunglasses').toUpperCase()}
            </OrderHistoryStyledButtonCTA>
            <OrderHistoryStyledButtonCTA
              fullWidth
              to={`${basePath}/${PLP_LINK_CONTACT_LENSES}`}
            >
              {t('Order.Shop.ShopContactLenses').toUpperCase()}
            </OrderHistoryStyledButtonCTA>
          </OrderHistoryEmptyCTAs>
        </OrderHistoryEmptyWrapper>
      ) : (
        <OrderHistoryContainerWrapper>
          {orders.map((order) => {
            const {
              orderId,
              orderStatus: orderInitStatus,
              orderExtendAttribute,
              placedDate,
              grandTotal,
              grandTotalCurrency,
              x_orderItemsCount: orderItemsCount,
            } = order

            const invoiceUrl =
              orderExtendAttribute.find(
                (attribute) => attribute.attributeName === 'xinvoiceUrls'
              )?.attributeValue || ''

            const itemsCount = parseInt(orderItemsCount ?? '0')

            const isOrderRoxable = OrderService.isRoxable(orderExtendAttribute)

            const returnedStatus = getOrderReturnedStatus(order)

            const orderStatus = returnedStatus || orderInitStatus

            const trackingUrl = (order.xtrackingUrls ?? {})[order.orderId]

            return (
              <OrderHistoryItem key={orderId}>
                <OrderHistoryItemTitleWrapper>
                  <OrderHistoryItemTitle>
                    <ProductImageWrapper>
                      {itemsCount > 1 ? (
                        <StyledBadge badgeContent={`+${orderItemsCount}`}>
                          <OrderHistoryImage
                            src={order['x_firstOrderItemThumbnail'] || url404}
                          />
                        </StyledBadge>
                      ) : (
                        <OrderHistoryImage
                          src={order['x_firstOrderItemThumbnail'] || url404}
                        />
                      )}
                    </ProductImageWrapper>
                    <span>
                      {t('Order.OrderId').toUpperCase()} {orderId}
                    </span>
                  </OrderHistoryItemTitle>

                  {isDesktop && (
                    <LinkAsButton
                      to={generateAccountPagePath(
                        langCode,
                        ACCOUNT_CHILDREN.ORDER_HISTORY,
                        { orderId }
                      )}
                      fillType="outline"
                    >
                      {t('Order.HistoryViewDetailTooltip').toUpperCase()}
                    </LinkAsButton>
                  )}
                </OrderHistoryItemTitleWrapper>

                <OrderHistoryItemSeparator />

                <OrderHistoryItemInfo>
                  <div>{t('Order.Date').toUpperCase()}</div>
                  <div>
                    {DateService.format(placedDate || '', 'dd/MM/yyyy')}
                  </div>
                  <div>{t('Order.Status').toUpperCase()}</div>
                  <OrderHistoryStatusContainer>
                    {PENDING_PRESCRIPTION_STATUSES.includes(orderStatus) ? (
                      <>
                        <OrderHistoryPrescriptionLabel>
                          {t(`Order.Status_.${orderStatus}`)}
                        </OrderHistoryPrescriptionLabel>
                        <OrderHistoryStyledAnchorCTA
                          to={formatPathWithBase(
                            `${PRESCRIPTION_VERIFICATION}?orderId=${orderId}`,
                            basePath
                          )}
                        >
                          {t('Order.OrderHistoryPendingPrescriptionCta')}
                        </OrderHistoryStyledAnchorCTA>
                      </>
                    ) : SHIPPED_STATUSES.includes(orderStatus) ? (
                      <>
                        <OrderHistoryShippedLabel>
                          {t(`Order.Status_.${orderStatus}`)}
                        </OrderHistoryShippedLabel>
                        {trackingUrl &&
                          parseInt(orderItemsCount) === 1 &&
                          orderStatus !== ORDER_ITEM_STATUS.Shipped_Settled && (
                            <OrderHistoryStyledAnchorCTA to={trackingUrl}>
                              {t('Order.OrderhistoryTrackYourOrderCta')}
                            </OrderHistoryStyledAnchorCTA>
                          )}
                      </>
                    ) : ORDER_STATUSES.includes(orderStatus) ? (
                      <OrderHistoryShippedLabel>
                        {t('Order.StateCompleted')}
                      </OrderHistoryShippedLabel>
                    ) : (
                      t(`Order.Status_.${orderStatus}`)
                    )}

                    {!!invoiceUrl &&
                      orderStatus !== 'D' &&
                      !ORDER_ITEM_STATUS.Delivered.includes(orderStatus) && (
                        <OrderHistoryStyledAnchorCTA to={invoiceUrl}>
                          {t('OrderDetails.Actions.DownloadInvoice')}
                        </OrderHistoryStyledAnchorCTA>
                      )}
                    {ORDER_STATUSES.includes(orderStatus) && (
                      <OrderHistoryStyledLinkCTA
                        onClick={() => onOrderAgain(orderId, isOrderRoxable)}
                      >
                        {t('OrderDetails.Actions.OrderAgain')}
                      </OrderHistoryStyledLinkCTA>
                    )}
                  </OrderHistoryStatusContainer>
                  <div>{t('Order.TotalPrice')}</div>
                  <div>
                    <FormattedPriceDisplay
                      min={+grandTotal}
                      currency={grandTotalCurrency}
                    />
                  </div>
                </OrderHistoryItemInfo>

                {isMobile && (
                  <LinkAsButton
                    to={generateAccountPagePath(
                      langCode,
                      ACCOUNT_CHILDREN.ORDER_HISTORY,
                      { orderId }
                    )}
                    fillType="outline"
                    fullWidth
                  >
                    {t('Order.HistoryViewDetailTooltip').toUpperCase()}
                  </LinkAsButton>
                )}
              </OrderHistoryItem>
            )
          })}
        </OrderHistoryContainerWrapper>
      )}
    </OrderHistoryWrapper>
  )
}

export default OrderHistory

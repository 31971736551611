import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { Product } from '../../../types/product'
import {
  PROFILE_NAME_PARAM,
  ITEM_DETAILS_PROFILE_NAME,
} from '../../../constants/common'
import {
  wishlistItemsSelector,
  wishlistProductsSelector,
} from '../../../features/wishList/selector'
import {
  DashboardTile,
  DashboardTileHeaderWrapper,
  DashboardTileHeader,
  DashboardTileContent,
} from '../AccountDashboard.style'
import {
  DashboardWishlistItems,
  DashboardWishlistItemHeaderWrapper,
  DashboardWishlistItemContainer,
  DashboardWishlistProductDetails,
  DashboardWishlistProductName,
  DashboardWishlistBrandName,
  StyledLinkAsButton,
} from './DashboardWishlist.style'
import { HeartIconSlim } from '@components/UI/Icons'
import ProductImage from '../../../components/ProductImage/ProductImage'
import productsService from '../../../foundation/apis/search/products.service'
import { getAllProductAttributes } from '@utils/productAttributes'
import { useTheme } from '@mui/material/styles'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { PLP_LINK_SUNGLASSES } from '../../../constants/ui'
import orderBy from 'lodash/orderBy'
import { dashboardAnalyticsBaseCtaElementId } from '../AccountDashboard'
import ProductAlgoliaUtils from '@utils/ProductAlgolia/ProductAlgoliaUtils'
import ProductPriceAlgoliaUtils from '@utils/ProductAlgolia/ProductPriceAlgoliaUtils'
import ProductPriceFinal from '@components/ProductPriceFinal'

const DashboardWishlist: FC = () => {
  const { country } = useStoreIdentity()
  const { t } = useTranslation()
  const { basePath } = useStoreIdentity()
  const theme = useTheme()
  const { isMobile, isTabletPortrait, isTabletLandscape, isDesktop } =
    useBreakpoints()
  //const isWishlistLoading = useSelector(isWishlistLoadingSelector)
  const wishlistItems = useSelector(wishlistItemsSelector)
  const productsCount = wishlistItems?.length
  const wishlistProducts = useSelector(wishlistProductsSelector)
  //TODO: remove the forced type once task pr is merged and product modal is normalized everywhere
  const [product, setProduct] = useState<Product | undefined>(
    (wishlistProducts?.[0] as any) || undefined
  )

  const [areProductsLoading, setProductsLoading] = useState<boolean>(false)
  //const partNumberToSet: string | undefined = product?.items?.[0]?.partNumber
  /*  const { soldOutStatus } = useProductSoldOutState({
    product,
    currentProductPartNumber: partNumberToSet ?? '',
  })*/

  const productImageWidth = useMemo<number>(() => {
    switch (true) {
      case isMobile:
        return 296
      case isTabletPortrait:
        return 65
      case isTabletLandscape:
        return 65
      case isDesktop:
        return 260
      default:
        return 260
    }
  }, [isMobile, isTabletPortrait, isDesktop])

  useEffect(() => {
    setProductsLoading(true)

    productsService
      .get({
        id: wishlistItems.map((item) => item.productId),
        [PROFILE_NAME_PARAM]: ITEM_DETAILS_PROFILE_NAME,
      })
      .then((products) => {
        if (products.contents?.length) {
          let p = products.contents[0]

          if (wishlistItems.length) {
            const wishListItemsOrdered = orderBy(
              wishlistItems,
              'itemLastUpdate',
              'desc'
            )
            const mostRecentProduct = products.contents.find(
              (product) => product.id === wishListItemsOrdered[0].productId
            )
            if (mostRecentProduct) {
              p = mostRecentProduct
            }
          }

          // TODO: remove all references to services afer new pdp model task merge
          setProduct({
            ...p,
            productAttributes: getAllProductAttributes(p.attributes),
          } as any)
        }
        setProductsLoading(false)
      })
  }, [wishlistProducts, wishlistItems])

  const { modelName, brand, lxSearchPageProductType } =
    ProductAlgoliaUtils.getProductAttributes(product?.productAttributes!)

  return !productsCount ? (
    <DashboardTile>
      <DashboardTileHeaderWrapper>
        <HeartIconSlim
          width={48}
          height={48}
          htmlColor={theme.palette.text.dark}
        />
        <DashboardTileHeader>
          {t('AccountDashboard.MyFavs')}
        </DashboardTileHeader>
      </DashboardTileHeaderWrapper>

      <DashboardTileContent>
        {t('AccountDashboard.BrowseLatestStyles')}
      </DashboardTileContent>
      <StyledLinkAsButton
        dataElementId={dashboardAnalyticsBaseCtaElementId}
        data-description="MyFavourites"
        to={`${basePath}/${PLP_LINK_SUNGLASSES}`}
      >
        {t('AccountDashboard.MyFavourites')}
      </StyledLinkAsButton>
    </DashboardTile>
  ) : (
    <DashboardWishlistItemContainer>
      <DashboardWishlistItemHeaderWrapper>
        <DashboardTileHeader>
          {t('AccountDashboard.MyFavs')}
        </DashboardTileHeader>
        <DashboardWishlistItems>
          {t('AccountDashboard.Items', { count: productsCount }).toUpperCase()}
        </DashboardWishlistItems>
      </DashboardWishlistItemHeaderWrapper>

      <DashboardTileContent>
        <ProductImage
          alt="Product image"
          attachments={product?.attachments}
          usage="PLP"
          width={productImageWidth}
          backgroundColor={theme.palette.color.white.main}
        />
      </DashboardTileContent>
      <DashboardWishlistProductDetails>
        <DashboardWishlistProductName>
          {modelName ?? ''}
        </DashboardWishlistProductName>
        <DashboardWishlistBrandName>{brand ?? ''}</DashboardWishlistBrandName>

        {!areProductsLoading && product?.x_price && (
          <ProductPriceFinal
            pageType={'plp'}
            productType={lxSearchPageProductType}
            price={
              ProductPriceAlgoliaUtils.getDeterminatePrice(
                product.x_price!,
                country
              )!
            }
          />
        )}
      </DashboardWishlistProductDetails>
      <StyledLinkAsButton
        dataElementId={dashboardAnalyticsBaseCtaElementId}
        data-description="MyFavourites"
        to={`${basePath}/wishlist`}
      >
        {t('AccountDashboard.ViewAllFavourites')}
      </StyledLinkAsButton>
    </DashboardWishlistItemContainer>
  )
}

export default DashboardWishlist

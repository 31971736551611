import { createSelector } from '@reduxjs/toolkit'
import omit from 'lodash/omit'
import config from '../../../configs'
import { CommonData } from '../tealium/interfaces'
import {
  loginStatusSelector,
  userDetailsSelector,
} from 'src/redux/selectors/user'
import {
  appliedPromotionCodesSelector,
  cartSelector,
  orderDiscountNamesSelector,
  totalOrderDiscountSelector,
} from 'src/features/order/selector'
import {
  countrySelector,
  defaultCurrencyIdSelector,
  languageSelector,
} from 'src/redux/selectors/site'
import md5 from 'crypto-js/md5.js'
import sha256 from 'crypto-js/sha256.js'
import { segmentsSelector } from '../../../features/userSegments/selectors'
import { USER_SEGMENT_GOOGLE_SHOPPING } from '../../../constants/common'

const storeIdentitySelector = createSelector(
  countrySelector,
  languageSelector,
  (countryCode = config.defaultCountry, locale = config.defaultLocale) => {
    return {
      langCode: locale.slice(0, 2),
      locale,
      country: countryCode,
    }
  }
)

/**
 * This selector is a replacement for the useAnalyticsData hook.
 * This is more universal and can be used in sagas and other places where hooks are not available.
 * */
export const createAnalyticsDataSelector = (pageType?: string) =>
  createSelector(
    storeIdentitySelector,
    userDetailsSelector,
    loginStatusSelector,
    totalOrderDiscountSelector,
    orderDiscountNamesSelector,
    appliedPromotionCodesSelector,
    cartSelector,
    defaultCurrencyIdSelector,
    segmentsSelector,
    (
      storeIdentity,
      userDetails,
      isLoggedIn,
      totalOrderDiscount,
      orderDiscountNames = '',
      discountCodes = '',
      cart,
      currency,
      segments
    ): Partial<CommonData> => {
      const { langCode, country } = storeIdentity
      const orderDiscountAmount = (+totalOrderDiscount || 0).toFixed(2)
      const userEmail = userDetails?.email1
      const userPhone = (userDetails?.phone1 || '').replace(/\D/g, '')

      const userData: Partial<CommonData> = isLoggedIn
        ? {
            User_Email_MD5: userEmail && md5(userEmail).toString(),
            User_Email_SHA256: userEmail && sha256(userEmail).toString(),
            User_Phone_MD5: userPhone && md5(userPhone).toString(),
            User_Phone_SHA256: userPhone && sha256(userPhone).toString(),
            User_LoginType: 'Standard',
          }
        : {}

      const baseAnalyticsData: Partial<CommonData> = {
        ...userData,
        Page_Brand: 'GV',
        Page_Design: '',
        Page_Country: country.toUpperCase(),
        Page_Language: langCode.toUpperCase(),
        Page_DeviceType: 'X',
        Page_Platform: 'WCS',
        Page_Server: '23.218.225.8',
        Page_Section2: '',
        Order_Currency: `${currency}`,
        User_Country: userDetails ? 'IT' : '',
        User_Id: userDetails?.userId,
        User_LoginStatus: isLoggedIn ? 'Logged' : 'Guest',
      }

      const orderDiscountName = segments.includes(USER_SEGMENT_GOOGLE_SHOPPING)
        ? 'DYNAMIC_GOOGLE'
        : orderDiscountNames

      const baseCheckoutAnalyticsData: Partial<CommonData> = {
        ...baseAnalyticsData,
        Order_DiscountAmount: orderDiscountAmount,
        Order_DiscountName: orderDiscountName,
        Order_DiscountCode: discountCodes,
        Order_CartId: cart?.orderId,
        Order_ProductsAmount: cart ? (+cart.grandTotal).toFixed(2) : '0',
      }

      switch (pageType) {
        case 'notFound':
          return omit(baseAnalyticsData, ['Order_Currency'])
        case 'home':
          return baseAnalyticsData
        case 'cart':
          return {
            ...baseAnalyticsData,
            Order_DiscountAmount: orderDiscountAmount,
            Order_DiscountName: orderDiscountName,
            Order_CartId: cart?.orderId,
          }
        case 'promo':
          return {
            Order_DiscountCode: discountCodes,
            Order_DiscountName: orderDiscountNames,
          }
        case 'delivery':
        case 'typ':
          return baseCheckoutAnalyticsData
        case 'payment':
          return {
            ...baseCheckoutAnalyticsData,
            Events_UserEmailSub: userDetails?.x_data.hasNewsletter ? '1' : '0',
          }
        case 'page':
          return baseAnalyticsData
        case 'plp':
        case 'pdp':
        case 'profile':
        case 'signin':
        case 'wishlist':
        case 'storeLocator':
        default:
          return baseAnalyticsData
      }
    }
  )

//TODO: remove this until product model is normalized everywhere
// @ts-nocheck
import React from 'react'
import { OrderItem } from '../../types/order'
import ProductUtils from '@utils/ProductUtils'
import {
  DiscountedPrice,
  OriginalPrice,
  PriceContainer,
} from './OrderRecapItemPrice.style'
import { useSelector } from 'react-redux'
import { catentriesSelector } from '../../features/order/selector'
import { getDetailedPrices } from '@utils/rx'
import { isCLAccessoriesProduct, isContactLensesProduct } from '@utils/product'
import FormattedPriceDisplay from '@components/formatted-price-display'
import { getOrderItemContactLensesData } from '@utils/order'
import sum from 'lodash/sum'

type Props = {
  orderItem: OrderItem
  hideDiscountPrice?: boolean
}
const OrderRecapItemPrice = ({ orderItem, hideDiscountPrice }: Props) => {
  const catentries = useSelector(catentriesSelector)
  const currentProduct = catentries?.[orderItem.productId]

  const parsedFramePrice = orderItem.roxableServices
    ? currentProduct?.price.map((price) => ({
        ...price,
        value: orderItem.x_offerpriceRx || price.value,
      })) || []
    : currentProduct?.price || []

  const { totalInitialPrice, totalCurrentPrice } =
    getDetailedPrices(parsedFramePrice, orderItem?.roxableServices) || {}

  const initialPrice =
    totalInitialPrice && ProductUtils.getInitialPrice([totalInitialPrice])
  const currentPrice =
    totalCurrentPrice && ProductUtils.getCurrentPrice([totalCurrentPrice])

  const itemDiscount =
    orderItem?.adjustment?.find((a) => a.usage === 'Discount')?.amount || 0
  const siblingItemDiscount =
    orderItem?.sibilingOrderItem?.adjustment?.find(
      (a) => a.usage === 'Discount'
    )?.amount || 0

  const totalDiscount = Math.abs(+itemDiscount) + Math.abs(+siblingItemDiscount)

  const getTotalBoxesCL = (): number => {
    try {
      const orderItemClData: OrderItemContactLensData | null = !!orderItem
        ? getOrderItemContactLensesData(orderItem, [
            'x_productId',
            'x_eye',
            'x_partNumber',
            'x_dominance',
          ])
        : null

      const rightEyeQuantity = orderItemClData.right.quantity || 0
      const leftEyeQuantity = orderItemClData.left.quantity || 0

      const totalBoxes = sum([
        parseInt(leftEyeQuantity),
        parseInt(rightEyeQuantity),
      ])

      return totalBoxes
    } catch (error) {
      return +orderItem.quantity
    }
  }

  const initialTotal = (initialPrice || 0) * getTotalBoxesCL()

  const updatedBoxPrice =
    +orderItem.orderItemPrice +
    +(orderItem?.sibilingOrderItem?.orderItemPrice || 0) -
    totalDiscount

  const price =
    initialPrice && currentPrice ? (
      <PriceContainer>
        {initialPrice > currentPrice && !hideDiscountPrice && (
          <OriginalPrice>
            <FormattedPriceDisplay
              min={
                isContactLensesProduct(orderItem) ||
                isCLAccessoriesProduct(orderItem)
                  ? initialTotal
                  : initialPrice
              }
              currency={orderItem.currency}
            />
          </OriginalPrice>
        )}
        <DiscountedPrice>
          <FormattedPriceDisplay
            min={
              isContactLensesProduct(orderItem) ||
              isCLAccessoriesProduct(orderItem)
                ? updatedBoxPrice
                : currentPrice
            }
            currency={orderItem.currency}
          />
        </DiscountedPrice>
      </PriceContainer>
    ) : null

  return price
}

export default OrderRecapItemPrice

//Standard libraries
import React, { FC, useEffect, useState } from 'react'
import Axios, { Canceler } from 'axios'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import getDisplayName from 'react-display-name'
//UI
import UserAddress from './components/UserAddress'
import AddAddressForm from './components/AddAddressForm'
//Types
import { Contact } from '../../types/user'
//Redux
import { userDetailsSelector } from '../../redux/selectors/user'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../redux/actions/user'
import { sendSuccessMessage } from '../../features/success/slice'
//Foundation libraries
import { sendAccountInformationEvent } from '../../foundation/analytics/tealium/lib'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

import Log from '../../services/Log'
import personContactService from '../../foundation/apis/transaction/personContact.service'

//Styles
import {
  AddressBookTitle,
  AddAddressButtonContainer,
  AddressBookWrapper,
  UserAddressContainer,
  StyledAddAddressBookButton,
} from './AddressBook.style'
import useScrollTo from '../../hooks/useScrollTo'
import { useAnalyticsData } from 'src/foundation/analytics/hooks/useAnalyticsData'

const AddressBook: FC = () => {
  const widgetName = getDisplayName(AddressBook)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { country: storeCountry } = useStoreIdentity()
  const userDetails = useSelector(userDetailsSelector)
  const contactList = userDetails?.contact || []
  const scrollTo = useScrollTo()
  const analyticsData = useAnalyticsData('profile')

  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false)
  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const updateAddress = (c: Contact) => {
    personContactService
      .updatePersonContact({
        body: c,
        ...payloadBase,
      })
      .then((res) => res.data)
      .then((addressData) => {
        if (addressData.addressId) {
          dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION({ ...payloadBase }))

          dispatch(
            sendSuccessMessage({
              key: 'success-message.EDIT_ADDRESS_SUCCESS',
              messageParameters: {
                '0': c.nickName,
              },
            })
          )
        }
      })
      .catch((e) => {
        Log.error('Could not update address', e)
      })
  }
  const addressList = contactList.filter(
    (contact) =>
      contact.country.toLowerCase() === storeCountry.toLowerCase() &&
      ['shipping'].includes(contact.addressType.toLowerCase())
  )

  useEffect(() => {
    const defaultAddress = addressList.filter(
      (contact) => contact.primary === 'true'
    )

    if (defaultAddress.length === 0 && addressList.length > 0) {
      const newDefaultAddress = { ...addressList[0], primary: 'true' }

      updateAddress(newDefaultAddress)
    }
  }, [addressList, updateAddress])

  useEffect(() => {
    sendAccountInformationEvent({
      common: analyticsData,
      Page_Section2: 'AddressBook',
    })

    if (!userDetails) {
      dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION(payloadBase))
    }

    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [userDetails])

  return (
    <AddressBookWrapper>
      <AddressBookTitle>{t('AddressBook.Title')}</AddressBookTitle>
      {!contactList.length ? (
        <AddAddressForm
          formData={null}
          payloadBase={payloadBase}
          onEditEnd={() => setIsAddingNewAddress(false)}
        />
      ) : (
        <>
          <UserAddressContainer>
            {addressList.map((c) => {
              return <UserAddress address={c} updateAddress={updateAddress} />
            })}
          </UserAddressContainer>
          {!isAddingNewAddress && (
            <AddAddressButtonContainer>
              <StyledAddAddressBookButton
                onClick={() => setIsAddingNewAddress(true)}
              >
                {t('AddressBook.AddrMsg')}
              </StyledAddAddressBookButton>
            </AddAddressButtonContainer>
          )}
          {isAddingNewAddress && (
            <AddAddressForm
              formData={null}
              payloadBase={payloadBase}
              onEditEnd={() => {
                scrollTo(0, 0)
                setIsAddingNewAddress(false)
              }}
            />
          )}
        </>
      )}
    </AddressBookWrapper>
  )
}

export default AddressBook

import {
  Attribute,
  ProductAttributes,
  ServerProduct,
  ServerProductAttribute,
} from '../types/product'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import split from 'lodash/split'

import { PRODUCT_TYPES_MAP } from '../constants/product'
import { TFunction } from 'react-i18next'
import rxService from '../foundation/apis/rx-config/rx.service'
import { IBadges } from '../redux/reducers'

export interface ProductBadges {
  isNew: boolean
  isBadge: boolean
  isLimitedEdition: boolean
  isExclusive: boolean
  isAvantPremiere: boolean
  isOnlineExclusive: boolean
  isSustainable: boolean
  isCustomizable: boolean
  isPolarized: boolean
  isMostPopular: boolean
  isRoxable: boolean
  clIsExclusive: boolean
  clIsNewArrival: boolean
}

type Product = Pick<ServerProduct, 'attributes' | 'cluster'>

//TODO: Extract this whole file into a Class with static methods in order to centralize better imports
//TODO: Wouldn't it be better to use constants for product keys for better organization and documentation?

//TODO: If class is extracted, turn this into a readonly property
export const PRODUCT_ATTRIBUTE_TRUE_VALUE = 'True' as const

//TODO: force return type in order to create better consistency and better type suggestions
export const getProductAttribute = (
  p?: Product,
  key?: string,
  subKey?: string
) => {
  const attributes = p?.attributes || []
  let attribute = attributes.find((attr) => attr.identifier === key)
  if (!attribute) {
    const clusters = p?.cluster
    if (clusters && clusters.length) {
      const cluster = clusters[0]
      const clusterAttributes = cluster?.attributes || []
      attribute = clusterAttributes.find((attr) => attr.identifier === key)
    }
  }
  if (subKey) {
    const item = attribute?.values?.find((v) => v.identifier === subKey)
    return item ? item.value : ''
  } else {
    return get(attribute, 'values[0].value', '')
  }
}
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getFrameShape = (p: Product) =>
  getProductAttribute(p, 'FRAME_SHAPE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getFrameMaterial = (p: Product) =>
  getProductAttribute(p, 'FRAME_MATERIAL_CLASS')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getFrameMaterialFacet = (p: Product) =>
  getProductAttribute(p, 'FRAME_MATERIAL_FACET')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getTempleColorFacet = (p: Product) =>
  getProductAttribute(p, 'TEMPLE_COLOR_FACET')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getFrontColorFacet = (p: Product) =>
  getProductAttribute(p, 'TEMPLE_COLOR_FACET')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getColorCode = (p: Product) => getProductAttribute(p, 'COLOR_CODE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsPolarized = (p: Product) =>
  getProductAttribute(p, 'POLARIZED')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsMostPopular = (p: Product) =>
  getProductAttribute(p, 'LX_IS_MOST_POPULAR')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsRoxable = (p: Product) =>
  getProductAttribute(p, 'ROXABLE') === PRODUCT_ATTRIBUTE_TRUE_VALUE
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsSustainable = (p: Product) =>
  getProductAttribute(p, 'SUSTAINABILITY_CLAIM')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsCustomizable = (p: Product) =>
  getProductAttribute(p, 'CUSTOMIZABLE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsOnlineExclusive = (p: Product) =>
  getProductAttribute(p, 'WEB EXCLUSIVE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsExclusive = (p: Product) =>
  getProductAttribute(p, 'EXCLUSIVE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsAvantPremiere = (p: Product) =>
  getProductAttribute(p, 'AVANT_PREMIERE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getIsLimitedEdition = (p: Product) =>
  getProductAttribute(p, 'LIMITED_EDITION')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getAnnualSupplyBadge = (p: Product) =>
  getProductAttribute(p, 'CL_ANNUAL_SUPPLY_BADGES')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getSupplyDuration = (p: Product) =>
  getProductAttribute(p, 'CL_SUPPLY_DURATION')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getNew = (p: Product) => getProductAttribute(p, 'IS_NEW')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getBadge = (p: Product) => getProductAttribute(p, 'BADGE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getSoldOut = (p: Product) => getProductAttribute(p, 'LX_SOLDOUT')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getClPackSize = (p: Product) =>
  getProductAttribute(p, 'CL_PACK_SIZE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getClVolume = (p: Product) => getProductAttribute(p, 'CL_VOLUME')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getModelCodeDisplay = (p?: Product) =>
  getProductAttribute(p, 'MODEL_CODE_DISPLAY')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getFrontColor = (p?: Product) =>
  getProductAttribute(p, 'FRONT_COLOR')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getLensesColor = (p?: Product) =>
  getProductAttribute(p, 'LENS_COLOR')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getLensesColorFacet = (p?: Product) =>
  getProductAttribute(p, 'LENS_COLOR_FACET')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getTempleColor = (p?: Product) =>
  getProductAttribute(p, 'TEMPLE_COLOR')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getLensesTreatment = (p: Product) =>
  getProductAttribute(p, 'LENS_TREATMENT_FACET')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getModelFamily = (p?: Product) =>
  getProductAttribute(p, 'PROD_HIERARCHY_3')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getLensMacroMaterial = (p: Product) =>
  getProductAttribute(p, 'LENS_MATERIAL_MACRO_CLASS')
export const getContactLensesParams = (
  p: ServerProduct
): Attribute | undefined =>
  p.orderItemExtendAttribute.find(
    (attr) => attr.attributeName === 'x_contactLens'
  )

const getPriorityBadgeText = (badges: ProductBadges, t: TFunction): string => {
  switch (true) {
    case badges.isExclusive:
      return t('ProductTile.Labels.exclusive')
    case badges.isOnlineExclusive:
      return t('ProductTile.Labels.onlineExclusive')
    case badges.isAvantPremiere:
      return t('ProductTile.Labels.avantPremiere')
    case badges.isNew:
      return t('ProductTile.Labels.new')
    case badges.isMostPopular:
      return t('ProductTile.Labels.mostPopular')
    default:
      return ''
  }
}
/**
 * @deprecated Use productAttributes_new method
 */
export const getBadges = (
  product: Pick<ServerProduct, 'attributes'>,
  t: TFunction,
  plpBadges: IBadges = {} as IBadges,
  isRXEnabled = false
): {
  // `badges: IBadges` can also be exported in case of need
  primaryBadge: string
  secondaryBadges: string
} => {
  const badges: ProductBadges = {
    isBadge: plpBadges.BADGE === getBadge(product),
    isNew: plpBadges.IS_NEW === getNew(product),
    isLimitedEdition:
      plpBadges.LIMITED_EDITION === getIsLimitedEdition(product),
    isOnlineExclusive:
      plpBadges['WEB EXCLUSIVE'] === getIsOnlineExclusive(product),
    isExclusive: plpBadges.EXCLUSIVE === getIsExclusive(product),
    clIsExclusive: plpBadges.CL_IS_EXCLUSIVE === getIsExclusive(product),
    clIsNewArrival: plpBadges.CL_IS_NEWARRIVAL === getIsExclusive(product),
    isAvantPremiere: plpBadges.AVANT_PREMIERE === getIsAvantPremiere(product),
    isRoxable:
      rxService.isProductRoxable(isRXEnabled, product) &&
      plpBadges.ROXABLE === PRODUCT_ATTRIBUTE_TRUE_VALUE,
    isCustomizable: plpBadges.CUSTOMIZABLE === getIsCustomizable(product),
    isPolarized: plpBadges.POLARIZED === getIsPolarized(product),
    isMostPopular:
      plpBadges.LX_IS_MOST_POPULAR_TRUE === getIsMostPopular(product),
    isSustainable: plpBadges.SUSTAINABILITY_CLAIM === getIsSustainable(product),
  }
  const primaryBadge = getPriorityBadgeText(badges, t)

  const currentLensTreatment = getLensesTreatment(product)
  const activeBadges = Object.keys(
    pickBy(
      {
        Polarised: badges.isPolarized,
        Sustainable: badges.isSustainable,
        isLensTreatment:
          currentLensTreatment === 'Photochromic' ||
          currentLensTreatment === 'Transitions®',
      },
      (cb) => cb
    )
  )

  const secondaryBadges = (
    activeBadges.length > 2 ? activeBadges.slice(0, 2) : activeBadges
  )
    .map((el) => (el === 'isLensTreatment' ? 'Light-Adaptive' : el))
    .join(' | ')

  // `badges` can also be exported in case of need
  return { primaryBadge, secondaryBadges }
}

export const getCaseImageUrl = (p: Product) =>
  getProductAttribute(p, 'INCLUDED_ITEMS', 'INCLUDED_ITEMS_IMAGE_URL')

export const PRODUCT_SIZES_NLS_BASE = 'ProductTile.Sizes.'
/** Returns formatted product size if TFunction from react-i18n was passed as argument.
 * Otherwise returns value of FRAME_SIZE_DISPLAY attribute */
export const getSize = (p: Product, t?: TFunction<'translation'>): string => {
  const frameSizeDisplay = getProductAttribute(p, 'FRAME_SIZE_DISPLAY')
  const productSizeNls = PRODUCT_SIZES_NLS_BASE + frameSizeDisplay

  return t && productSizeNls ? t(productSizeNls) : frameSizeDisplay
}

/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getHingeDistance = (product?: Product) =>
  getProductAttribute(product, 'HINGE_DISTANCE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getMostSoldSize = (p: Product) =>
  getProductAttribute(p, 'MOST_SOLD_SIZE')

/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getFrameSize = (p?: Product) =>
  getProductAttribute(p, 'FRAME_SIZE_DISPLAY')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getFrame = (p?: Product) => getProductAttribute(p, 'FRAME_SIZE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getBrand = (p?: Product) => getProductAttribute(p, 'BRAND')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getCLBrand = (p?: Product) => getProductAttribute(p, 'CL_BRAND')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getModelName = (p?: Product) =>
  getProductAttribute(p, 'MODEL_NAME')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getProductType = (p?: Product) =>
  getProductAttribute(p, 'PRODUCT_TYPE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getQuantity = (p?: ServerProduct): number | undefined =>
  Number(p?.quantity)
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getNormalizedProductType = (p?: Product) => {
  const pt: string = getProductType(p)
  const res = pt && PRODUCT_TYPES_MAP[pt.toLowerCase()]
  return res
}
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getSearchPageProductType = (p?: Product) =>
  getProductAttribute(p, 'LX_SEARCH_PAGE_PRODUCT_TYPE')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getModelCode = (p: Product) => getProductAttribute(p, 'DISPLAYSKU')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getBridgeWidth = (p?: Product) =>
  getProductAttribute(p, 'BRIDGE_WIDTH')

/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getLensWidth = (p: Product) => getProductAttribute(p, 'LENS_WIDTH')

/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getTempleLength = (p?: Product) =>
  getProductAttribute(p, 'TEMPLE_LENGTH')
/**
 * @deprecated Use productAttributes array prop in Product model
 */
export const getLensHeight = (product?: Product) =>
  getProductAttribute(product, 'LENS_HEIGHT')

// TODO: remove this function since it is never used nor imported
export const getCanonicalCategory = (p?: Product) => {
  // return getProductAttribute(p, 'LX_CANONICAL_CATEGORY')
  return get(
    p?.attributes.find((attr) => attr.identifier === 'LX_CANONICAL_CATEGORY'),
    'values[0].identifier',
    ''
  )
}

// TODO: remove this function since it is never used nor imported
export const getProductColorFacet = (facetsMap, key: string) => {
  let facetInfo = facetsMap.filter((facet) => {
    return key.includes(facet.key)
  })
  return facetInfo[0] || null
}

export const getAllProductAttributes = (
  attributes: ServerProductAttribute[] = []
): ProductAttributes => {
  return attributes.reduce(
    (acc: ProductAttributes, attr: ServerProductAttribute) => {
      const value = get(attr, 'values[0].value', '')
      return {
        ...acc,
        [attr.identifier]: value,
      }
    },
    {}
  )
}

// @TODO TEMP TO USE UNTIL WE HAVE IMAGES

// TODO: remove this function since it is never used nor imported
type ProductImageKind =
  | 'quarterShad'
  | 'frontShad'
  | 'lateralShad'
  | 'closedFrontShad'
  | 'backShad'
  | 'alternativeOneShad'
  | 'alternativeTwoShad'
  | 'alternativeNShad'
  | 'foldingShad'
  | 'foldingGroupShad'

// TODO: remove this function since it is never used nor imported
const productImageKindMap: { [key: string]: string } = {
  quarterShad: '030A',
  quarterNoShad: '000A',
  frontShad: '000A',
  lateralShad: '090A',
  closedFrontShad: 'cfr',
  closedFrontNoShad: 'crf',
  backShad: '180A',
  alternativeOneShad: 'al1',
  alternativeTwoShad: 'al2',
  alternativeNShad: 'alN',
  foldingShad: 'fld',
  foldingGroupShad: 'fldg',
  groupNoShad: 'grp',
  onModelNoShad: 'om',
  adv: 'adv',
  advn: 'advN',
}

// TODO: remove this function since it is never used nor imported
export const getTempImgUrlImageUrl = (
  partNumber: string,
  width: number,
  kind: ProductImageKind
): string => {
  // final string url example
  // https://my4c-im.luxottica.com/6S/06S2002/06S2002__200206_030A.png?imwidth=600
  const { productCode, moco, brand } = getProductInfo(partNumber)
  const baseUrl = new URL('https://my4c-im.luxottica.com')
  const brandPartialUrl = `/${brand}`
  const productPartialUrl = `/${productCode}`
  const productImageName = getProductImageName(productCode, moco, kind)
  const completeUrl = new URL(
    `${brandPartialUrl}${productPartialUrl}${productImageName}`,
    baseUrl
  )
  return `${completeUrl.toString()}?imwidth=${width}`
}

// TODO: remove this function since it is never used nor imported
export const getProductInfo = (partNumber) => {
  const [productCode, moco] = split(partNumber, '__', 2)
  const brand = productCode.substr(1, 2)
  return { productCode, moco, brand }
}

// TODO: remove this function since it is never used nor imported
export const getProductImageName = (
  productCode: string,
  moco: string,
  kind: ProductImageKind
) => {
  let baseName = `/${productCode}__${moco}`
  const kindKey = productImageKindMap[kind] || ''
  return `${baseName}_${kindKey}.png`
}

// END TEMP

import React from 'react'
import PlacementsSwitch from '../PlacementsSwitch'
import {
  AssociatedProduct,
  Product,
  ServerProduct,
} from '../../../types/product'
import LoadingSkeleton from '../CmsComponents/CmsCommonMedia/LoadingSkeleton'
import { IPlacement } from '../../../types/cmsPlacement/Placement'
import { IViewType } from '../../../types/cmsPlacement/ViewType'

const activePlacements: IViewType[] = [
  'full-width-banner',
  'landscape-banner',
  'boards-with-fields-below',
  'text-module',
  'squat-banner',
  'top-page-banner',
  'box-with-margin',
  'grid-of-boards-two-columns',
  'grid-of-boards-three-columns',
  'square-boards-with-split',
  'square-boards-without-split',
  'faqs',
  'grid-of-products',
  'dcw-products',
  'combo-mini-slider-plus-box-all-fields',
  'query-list',
  'wall-of-brands',
  'you-may-also-like',
  'size-guide',
  'faq-search',
  'Algolia Recommendations',
  'Webform',
]

const PlacementLayout: React.FC<{
  placements: IPlacement[]
  currentProduct?: ServerProduct | Product
  suggestedProducts?: AssociatedProduct[]
  loading: boolean
}> = ({ placements, currentProduct, suggestedProducts, loading }) => (
  <div className="placements-container">
    {loading ? (
      <LoadingSkeleton height={'100vh'} />
    ) : placements && placements.length === 0 ? null : (
      <PlacementsSwitch
        placements={placements}
        currentProduct={currentProduct}
        suggestedProducts={suggestedProducts}
        activePlacements={activePlacements}
      />
    )}
  </div>
)

export default PlacementLayout

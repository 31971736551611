import React, { useState } from 'react'
import { Logo } from '@components/UI'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { styled } from '@mui/material/styles'
import { useCheckoutPaths } from '../../../../../foundation/hooks/useCheckoutPaths'
import { useLocation, useMatch } from 'react-router'
import OrderRecapCartEditConfirmDialog from '../../../../../components/order-recap/OrderRecapEditBag'
import { Link } from 'react-router-dom'
import useBreakpoints from '../../../../../hooks/useBreakpoints'
import * as ROUTES from '../../../../../constants/routes'
import { CheckoutRouteKey } from '../../../../../types/common'

const NavLogoWrapper = styled(Link, {
  name: 'NavLogo',
  slot: 'Wrapper',
})(() => ({
  display: 'inherit',
}))

const NavigationLogo: React.FC = () => {
  const { basePath } = useStoreIdentity()
  const { isDesktop } = useBreakpoints()
  const location = useLocation()
  const match = useMatch(location.pathname)
  const { langCode } = useStoreIdentity()
  const checkoutPaths = useCheckoutPaths(langCode)
  const isShippingPage = match?.pathname === checkoutPaths.shipping
  const isPaymentPage = match?.pathname === checkoutPaths.payment
  const width = !isDesktop ? 120 : 126
  const height = !isDesktop ? 36 : 40

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const handleOpen = (): void => setIsEditOpen(true)
  const handleClose = (): void => setIsEditOpen(false)

  return (
    <>
      <NavLogoWrapper
        to={!isShippingPage && !isPaymentPage ? basePath : '#'}
        aria-label="Homepage"
        data-element-id="X_X_MainNav_LOGO"
        onClick={!isShippingPage && !isPaymentPage ? undefined : handleOpen}
      >
        <Logo type="dark-primary" width={width} height={height} />
      </NavLogoWrapper>
      <OrderRecapCartEditConfirmDialog
        open={isEditOpen}
        handleClose={handleClose}
        redirectTo={ROUTES.CART as CheckoutRouteKey}
      />
    </>
  )
}

export default NavigationLogo

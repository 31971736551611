import {
  ILXTeaser,
  ITeaserOverlaySettings,
  ITeaserOverlayStyle,
} from '../../../../types/cmsPlacement/LXTeaser'
import { getSrcSetsImageFromCms, getVideoFromCMS } from '@utils/url'
import LoadingSkeleton from './LoadingSkeleton'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { TPlayerBannerHook } from '@hooks/useBannerPlayer'
import { styled } from '@mui/material/styles'
import {
  teaserOverlayBackgroundGradient,
  teaserPropsByView,
} from '@utils/placements'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Helmet } from 'react-helmet'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import { IMedia } from '../../../../types/cmsPlacement/Media'
import { IViewType } from '../../../../types/cmsPlacement/ViewType'
import { Z_INDEX_LV1 } from 'src/constants/ui'
import { isPictureMedia } from 'src/types/cmsPlacement/Placement'
import {
  getCoreMediaImageDimensions,
  getImageRatioandWidthFromUrl,
  getImageResizedHeightByResizedWidth,
} from '@utils/cms'

export interface IProps {
  type: string
  media?: IMedia
  playerBannerHook?: TPlayerBannerHook
  isLazy?: boolean
  viewType?: IViewType
  teaser?: ILXTeaser
  isBackgroundGradientNeeded?: boolean
  isFetchPriority?: boolean
  hideSkeleton?: boolean
}

const ReactPlayer = React.lazy(() =>
  import(/* webpackChunkName: "videplayer" */ 'react-player').then((module) => {
    return { default: module.default }
  })
)

const StyledPlayerContainer = styled('div', {
  name: 'CmsCommonMedia',
  slot: 'PlayerContainer',
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'settings',
})<{
  color?: ITeaserOverlayStyle
  settings?: ITeaserOverlaySettings
}>(({ theme, color, settings }) => {
  const showGradient = Boolean(color && settings)
  return {
    height: '100%',
    position: 'relative',
    aspectRatio: 'inherit',

    ...(showGradient
      ? {
          [theme.breakpoints.up('xs')]: {
            '&::after': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              bottom: '10px',
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 25%, rgba(255,255,255,0) 50%);',
            },
          },
          [theme.breakpoints.up('md')]: {
            '&::after': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              bottom: '10px',
              ...teaserOverlayBackgroundGradient({ color, settings }),
            },
          },
        }
      : {
          zIndex: Z_INDEX_LV1,
        }),
    video: {
      objectFit: 'cover',
    },
  }
})

const GradientBackgroundContainer = styled('div', {
  name: 'CmsCommonMedia',
  slot: 'GradientBackgroundContainer',
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'settings',
})<{
  color?: ITeaserOverlayStyle
  settings?: ITeaserOverlaySettings
}>(({ color, settings, theme }) => ({
  position: 'relative',

  [theme.breakpoints.up('xs')]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      bottom: '10px',
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 25%, rgba(255,255,255,0) 50%);',
    },
  },

  [theme.breakpoints.up('md')]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      bottom: '10px',
      ...teaserOverlayBackgroundGradient({ color, settings }),
    },
  },

  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    objectFit: 'cover',
    verticalAlign: 'bottom',
  },
}))

const BackgroundContainer = styled('div', {
  name: 'CmsCommonMedia',
  slot: 'BackgroundContainer',
})(() => ({
  img: {
    width: '100%',
    height: '100%',
    maxWidth: '100vw',
    objectFit: 'cover',
    verticalAlign: 'bottom',
  },
}))

// const StyledReactPlayer = styled(ReactPlayer, {
//   name: 'CmsCommonMedia',
//   slot: 'Content',
//   shouldForwardProp: (prop) => prop !== 'toggleMuted',
// })({
//   objectFit: 'cover',
//   video: {},
// })

const FadeImg = styled('img', {
  name: 'CmsCommonMedia',
  slot: 'FadedImg',
  shouldForwardProp: (prop) => prop !== 'visible',
})<{
  visible?: boolean
}>(({ visible }) => ({
  transition: 'opacity 0.25s',
  opacity: visible ? '1' : '0',
}))

const SkeletonWrapper = styled('div', {
  name: 'CmsCommonMedia',
  slot: 'SkeletonWrapper',
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'width' && prop !== 'aspectRatio',
})<{
  height?: number | null
  width?: number | null
  aspectRatio?: string
}>(({ width, height, aspectRatio, theme }) => ({
  height: `${height}px`,
  aspectRatio: `${aspectRatio}`,
  width: `${width}px`,
  position: 'absolute',
  top: 0,
  transition: `all ${theme.transitions.duration.standard}ms ease-in-out`,
}))

const CMSCommonMedia: React.FC<IProps> = ({
  isLazy,
  type,
  media,
  playerBannerHook,
  teaser,
  viewType,
  isBackgroundGradientNeeded,
  isFetchPriority,
  hideSkeleton,
  ...rest
}) => {
  const {
    isMobile,
    isTabletPortrait,
    isTabletLandscape,
    isDesktopL,
    isDesktopS,
  } = useBreakpoints()
  const [show, setShow] = useState<boolean>(false)
  const [currentMediaContainerWidth, setCurrentMediaContainerWidth] = useState<
    number | undefined
  >(undefined)
  const video = media?.type === 'CMVideo' ? media : undefined
  const picture = isPictureMedia(media) ? media : undefined
  const { deskL, deskS, tabletL, tabletP, mobile } = getSrcSetsImageFromCms(
    type,
    picture?.uriTemplate ?? video?.picture?.uriTemplate ?? ''
  )

  const { teaserOverlaySettings, teaserOverlayStyle } = teaserPropsByView(
    viewType || 'default'
  )

  const Container = isBackgroundGradientNeeded
    ? GradientBackgroundContainer
    : BackgroundContainer

  const item = teaser
  const teaserOverlaySettingsValue =
    item?.[teaserOverlaySettings] || teaser?.[teaserOverlaySettings]
  const teaserOverlayStyleValue =
    item?.[teaserOverlayStyle] || teaser?.[teaserOverlayStyle]
  const fetchPriority = isFetchPriority ? 'high' : undefined
  const commonMediaContainerRef = useRef<HTMLDivElement>(null)
  const skeletonrRef = useRef<HTMLDivElement>(null)
  const urlVideoCover = () => {
    switch (true) {
      case isDesktopL:
        return deskL
      case isDesktopS:
        return deskS
      case isTabletLandscape:
        return tabletL
      case isTabletPortrait:
        return tabletP
      case isMobile:
        return mobile
      default:
        return deskL
    }
  }

  const renderSkeleton = ({
    height,
    width,
    aspectRatio,
  }: {
    height?: number | null
    width?: number | null
    aspectRatio?: number[]
  }) => {
    try {
      return (
        <SkeletonWrapper
          ref={skeletonrRef}
          height={height}
          width={width}
          aspectRatio={`${aspectRatio?.findIndex(
            (element) => element == 0
          )}/${aspectRatio?.findIndex((element) => element == 1)}`}
        >
          <LoadingSkeleton height={'100%'}></LoadingSkeleton>
        </SkeletonWrapper>
      )
    } catch {
      return null
    }
  }

  useEffect(() => {
    setCurrentMediaContainerWidth(
      skeletonrRef.current?.parentElement?.offsetWidth || window.innerWidth
    )
  }, [skeletonrRef.current])

  const coreMediaUrl = urlVideoCover()
  const imageDataFromImageUrl = getImageRatioandWidthFromUrl(coreMediaUrl || '')
  const skeletonWidth = getCoreMediaImageDimensions(coreMediaUrl || '')?.width

  const skeleletonHeight = useMemo(() => {
    return !!currentMediaContainerWidth
      ? getImageResizedHeightByResizedWidth(
          currentMediaContainerWidth,
          getCoreMediaImageDimensions(coreMediaUrl || '')?.width!,
          getCoreMediaImageDimensions(coreMediaUrl || '')?.height!
        )
      : null
  }, [currentMediaContainerWidth, coreMediaUrl])

  return (
    <>
      {!!isFetchPriority ? (
        <Helmet>
          {deskL && (
            <link
              rel="preload"
              as="image"
              media="(min-width: 1440px)"
              href={deskL}
            />
          )}
          {deskS && (
            <link
              rel="preload"
              as="image"
              media="(min-width: 1280px) and (max-width: 1439px)"
              href={deskS}
            />
          )}
          {tabletL && (
            <link
              rel="preload"
              as="image"
              media="(min-width: 1024px) and (max-width: 1279px)"
              href={tabletL}
            />
          )}
          {tabletP && (
            <link
              rel="preload"
              as="image"
              media="(min-width: 601px) and (max-width: 1023px)"
              href={tabletP}
            />
          )}
          {mobile && (
            <link
              rel="preload"
              as="image"
              media="(max-width: 600px)"
              href={mobile}
            />
          )}
        </Helmet>
      ) : null}

      {playerBannerHook && video ? (
        <LazyLoadComponent placeholder={<LoadingSkeleton height={'100%'} />}>
          <StyledPlayerContainer
            color={teaserOverlayStyleValue}
            settings={teaserOverlaySettingsValue}
            {...rest}
          >
            <ReactPlayer
              style={{
                objectFit: 'cover',
              }}
              url={getVideoFromCMS(video.data.uri)}
              width="100%"
              fallback={<LoadingSkeleton height={'100%'} />}
              height="100%"
              controls={!video.hideControl}
              loop={video.loop}
              muted={playerBannerHook.muted}
              playing={playerBannerHook.isPlaying}
              config={{
                file: {
                  attributes: {
                    poster: urlVideoCover(),
                  },
                },
              }}
              playsinline
              {...playerBannerHook.events}
            />
          </StyledPlayerContainer>
        </LazyLoadComponent>
      ) : isLazy ? (
        <LazyLoadComponent
          placeholder={
            <LoadingSkeleton height={`${skeleletonHeight}px` || '100%'} />
          }
        >
          <Container
            color={teaserOverlayStyleValue}
            settings={teaserOverlaySettingsValue}
            ref={commonMediaContainerRef}
          >
            {!show &&
              !hideSkeleton &&
              renderSkeleton({
                width: skeletonWidth,
                height: skeleletonHeight,
                aspectRatio: imageDataFromImageUrl?.ratio,
              })}
            <picture>
              {deskL && <source media="(min-width: 1440px)" srcSet={deskL} />}
              {deskS && <source media="(min-width: 1280px)" srcSet={deskS} />}
              {tabletL && (
                <source media="(min-width: 1024px)" srcSet={tabletL} />
              )}
              {tabletP && (
                <source media="(min-width: 601px)" srcSet={tabletP} />
              )}

              <FadeImg
                // @ts-ignore
                fetchpriority={fetchPriority}
                visible={show}
                src={mobile}
                alt={'cms-content__fwb-image'}
                loading="lazy"
                onLoad={() => {
                  setShow(true)
                }}
              />
            </picture>
          </Container>
        </LazyLoadComponent>
      ) : (
        <Container
          color={teaserOverlayStyleValue}
          settings={teaserOverlaySettingsValue}
          ref={commonMediaContainerRef}
        >
          {!show &&
            !hideSkeleton &&
            renderSkeleton({
              width: skeletonWidth,
              height: skeleletonHeight,
              aspectRatio: imageDataFromImageUrl?.ratio,
            })}

          <picture>
            {deskL && <source media="(min-width: 1440px)" srcSet={deskL} />}
            {deskS && <source media="(min-width: 1280px)" srcSet={deskS} />}
            {tabletL && <source media="(min-width: 1024px)" srcSet={tabletL} />}
            {tabletP && <source media="(min-width: 601px)" srcSet={tabletP} />}
            <FadeImg
              // @ts-ignore
              fetchpriority={fetchPriority}
              visible={show}
              src={mobile}
              alt={'cms-content__fwb-image'}
              onLoad={() => {
                setShow(true)
              }}
            />
          </picture>
        </Container>
      )}
    </>
  )
}

export default CMSCommonMedia

import { PaginationResponse } from '../../services/RequestService'
import { Product, ServerProduct } from '../../types/product'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { fromServerToClientProduct } from '@utils/fromServerToClientEntity'
import { RootReducerState } from '../../redux/reducers'

export interface IGetProductDetailsResult {
  product: Product
  serverProduct: ServerProduct
}

export interface IPdpProductArgs {
  partNumber: string
  profileName?: string
  contractId?: string
}

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v2/',
  }),

  endpoints: (build) => ({
    getProductDetails: build.query<IGetProductDetailsResult, IPdpProductArgs>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const state = queryApi.getState() as RootReducerState
        const storeId = state.site.currentSite?.storeID
        const catalogId = state.site.currentSite?.catalogID

        const params = {
          ...args,
          storeId,
          catalogId,
        }

        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: params || {},
          body: args['body'] || {},
          extraParams: { siteContextKey: 'search' },
        })

        return result?.data
          ? {
              data: {
                product: transformResponse(result.data) as Product,
                serverProduct: result.data.contents[0],
              },
            }
          : { error: result.error }
      },
    }),
  }),
})
const transformResponse = (
  data: PaginationResponse<ServerProduct & { type: 'item' }>
) => {
  return fromServerToClientProduct(data.contents[0])
  //console.log('TEST', fromServerToClientProduct(data.contents[0]))
  //return data
  //return data.contents ?? []
}
export const { useGetProductDetailsQuery, useLazyGetProductDetailsQuery } =
  productApi

import { Button, ButtonAsLink, LinkAsButton, TextField } from '@components/UI'
import { GridContainer, GridItem } from '../../../../components/UI'
import { styled } from '@mui/material/styles'
import { Drawer } from '@mui/material'
import CmsTeaserTitle from '@components/Cms/CmsComponents/CmsTeaserTitle'
import CmsTeaserDetailText from '@components/Cms/CmsComponents/CmsTeaserDetailText'
import { Z_INDEX_LV1 } from '../../../../constants/ui'

export const NewsletterWrapper = styled(GridContainer, {
  name: 'Newsletter',
  slot: 'Wrapper',
})(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.color.grey.light}`,
  gridColumnGap: theme.spacing(4),
  width: '100vw',
  marginLeft: 'calc(-50vw + 50%)',
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up('sm')]: {
    borderBottom: `1px solid ${theme.palette.color.grey.light}`,
    padding: theme.spacing(0, 8),
  },

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 16),
    marginTop: theme.spacing(4),
  },
}))

export const NewsLetterItem = styled(GridItem, {
  name: 'Newsletter',
  slot: 'Item',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4, 0),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8, 0),
    alignSelf: 'baseline',
  },
}))

export const ItemContent = styled('div', {
  name: 'Newsletter',
  slot: 'ItemContent',
})(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.4,
  color: theme.palette.text.light,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 72,
  alignItems: 'flex-start',

  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
  },
}))

export const ItemContentTitle = styled(CmsTeaserTitle, {
  name: 'Newsletter',
  slot: 'ItemContentTitle',
})(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'uppercase',
  paddingBottom: theme.spacing(4),
  fontSize: 16,
}))

export const ItemContentText = styled(CmsTeaserDetailText, {
  name: 'Newsletter',
  slot: 'ItemContentText',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),

  p: {
    margin: 0,
  },

  [theme.breakpoints.up('md')]: {
    lineHeight: 1.5,
    maxWidth: 561,
    marginBottom: 0,
  },
}))

export const NewsLetterEmailTextField = styled('div', {
  name: 'Newsletter',
  slot: 'EmailTextField',
})(({ theme }) => ({
  padding: theme.spacing(8, 0),
  width: '100%',
  // maxWidth: 343,

  [theme.breakpoints.up('md')]: {
    width: 561,
    maxWidth: 561,
    padding: theme.spacing(8, 0, 5),
  },
}))

export const StyledTextField = styled(TextField, {
  name: 'Newsletter',
  slot: 'TextField',
})(({ theme }) => ({
  '.MuiInputLabel-root': {
    '&.MuiInputLabel-shrink': {
      color: theme.palette.text.light,
    },
  },

  '&.MuiFormHelperText-root.Mui-error ': {
    bottom: 40,
  },
}))

export const NewsLetterStickyBar = styled('div', {
  name: 'Newsletter',
  slot: 'StickyBar',
  shouldForwardProp: (prop) => prop !== 'isSticky',
})<{ isSticky: boolean }>(({ theme, isSticky }) => ({
  display: isSticky ? 'flex' : 'none',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.light,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(2, 0, 2, 4),
  cursor: 'pointer',
  zIndex: Z_INDEX_LV1,

  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 4),
    right: 'auto',
  },
}))

export const NewsLetterStickyBarLabel = styled('div', {
  name: 'Newsletter',
  slot: 'StickyBarLabel',
})(({ theme }) => ({
  padding: theme.spacing(0, 11.5, 0, 2),
  width: '100%',
  whiteSpace: 'nowrap',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}))

export const CloseIconWrapper = styled('div', {
  name: 'Newsletter',
  slot: 'CloseIconWrapper',
})(({ theme }) => ({
  position: 'absolute',
  right: 16,
  bottom: 0,
  margin: 'auto',
  top: 3,

  [theme.breakpoints.up('sm')]: {
    right: 0,
  },
}))

export const NewsLetterSubscribeWrapper = styled('div', {
  name: 'Newsletter',
  slot: 'SubscribeWrapper',
  shouldForwardProp: (prop) => prop !== 'isShown',
})<{ isShown: boolean }>(({ theme, isShown }) => ({
  padding: theme.spacing(4, 0, 8),
  display: isShown ? 'block' : 'none',
}))

export const NewsLetterConsentText = styled('div', {
  name: 'Newsletter',
  slot: 'ConsentText',
})(({ theme }) => ({
  fontSize: 12,
  paddingBottom: theme.spacing(4),

  a: {
    color: theme.palette.text.light,
    textDecoration: 'underline',
  },
}))

export const NewsLetterSignUpAgainCTA = styled(ButtonAsLink, {
  name: 'Newsletter',
  slot: 'SignUpAgainCTA',
})(({ theme }) => ({
  fontSize: 16,
  paddingTop: theme.spacing(6),

  color: theme.palette.text.light,
  fontWeight: theme.typography.fontWeightBold,

  height: 'auto !important',

  '&:hover': {
    color: theme.palette.text.light,
  },

  '&:after, &:hover:after': {
    background: theme.palette.text.light,
  },
}))

export const NewsLetterSignUpAgainDrawerCTA = styled(ButtonAsLink, {
  name: 'Newsletter',
  slot: 'SignUpAgainCTA',
})(({ theme }) => ({
  fontSize: 16,
  paddingTop: theme.spacing(6),

  color: theme.palette.text.dark,
  fontWeight: theme.typography.fontWeightBold,

  height: 'auto !important',

  '&:hover': {
    color: theme.palette.text.dark,
  },

  '&:after, &:hover:after': {
    background: theme.palette.text.dark,
  },
}))

export const SideBannerContentWrapper = styled('div', {
  name: 'Newsletter',
  slot: 'SideBannerContentWrapper',
})(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(4),
  },
}))

export const SideBannerImageWrapper = styled('div', {
  name: 'Newsletter',
  slot: 'SideBannerContentWrapper',
})(({}) => ({
  width: 111,
  height: 128,

  div: {
    width: 111,
    height: 128,
  },
}))

export const SideBannerContent = styled('div', {
  name: 'Newsletter',
  slot: 'SideBannerContent',
})(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(8),
  },
}))

export const SideBannerCTA = styled(LinkAsButton, {
  name: 'Newsletter',
  slot: 'SideBannerCTA',
})(({ theme }) => ({
  color: theme.palette.text.light,
  border: `1px solid ${theme.palette.text.light}`,
  width: 'fit-content !important',
  bottom: 0,

  [theme.breakpoints.up('xs')]: {
    position: 'absolute',
  },

  [theme.breakpoints.up('md')]: {
    '&:hover': {
      color: theme.palette.text.light,
      border: `1px solid ${theme.palette.text.light}`,
    },
  },
}))

export const StyledDrawer = styled(Drawer, {
  name: 'Newsletter',
  slot: 'Drawer',
})(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    '&>div': {
      backgroundColor: 'transparent',
    },
  },
}))

export const RightItemContent = styled(ItemContent, {
  name: 'Newsletter',
  slot: 'RightItemContent',
})(({ theme }) => ({
  justifyContent: 'end',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'initial',
    lineHeight: 1.4,
  },
}))

export const SignUpButton = styled(Button, {
  name: 'Newsletter',
  slot: 'SignUpButton',
})(() => ({
  width: 109,
  minWidth: 109,
  padding: 0,
  fontSize: 14,
  fontWeight: 600,
}))

export const LogoWrapper = styled('div', {
  name: 'Newsletter',
  slot: 'LogoWrapper',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    justifyContent: 'flex-start',
  },
}))

export const CTAWrapper = styled('div', {
  name: 'Newsletter',
  slot: 'CTAWrapper',
})(({ theme }) => ({
  padding: 0,
  fontSize: 16,

  [theme.breakpoints.up('md')]: {
    fontSize: 20,
  },
}))

export const NewsLetterCTA = styled('span', {
  name: 'Newsletter',
  slot: 'CTA',
})(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.5,
  color: theme.palette.text.light,

  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
    marginLeft: theme.spacing(2),
  },
}))

import { AssociatedProduct, Product, ServerProduct } from '../types/product'
import { getAllProductAttributes } from './productAttributes'

export const fromServerToClientProduct = (
  product: ServerProduct,
  type?: string
): Product | AssociatedProduct | {} => {
  const commonProps = {
    name: product.name,
    type: product.type,
    id: product.id,
    uniqueID: product.uniqueID,
    purchasable: product.buyable === 'true',
    hasSingleSku: product.hasSingleSKU,
    numberOfSKUs: product.sKUs?.length || 0,
    storeID: product.storeID,
    seo: product.seo,
    parentCatalogGroupId: product.parentCatalogGroupID,
    prices: product.price,
    x_prices: product.x_prices,
    x_price: product.x_price,
    attachments: product.attachments,
    partNumber: product.partNumber,
    catalogEntryTypeCode: product?.catalogEntryTypeCode,
    attributes: product.attributes,
    productAttributes: getAllProductAttributes(product.attributes),
    longDescription: product?.longDescription,
  }
  switch (type) {
    case 'ProductBean':
      return {
        ...commonProps,
        items: product.items
          ? product.items?.map((item) =>
              fromServerToClientProduct(item, item?.catalogEntryTypeCode)
            )
          : product.sKUs?.map((sku) =>
              fromServerToClientProduct(sku, sku?.catalogEntryTypeCode)
            ),
        merchandisingAssociations:
          product.merchandisingAssociations
            ?.map((association) => {
              return association.cluster?.map((cluster) => {
                return fromServerToClientProduct(cluster, 'AssociatedProduct')
              })
            })
            .flat() || [],
      }
    case 'ItemBean':
      return {
        ...commonProps,
      }
    case 'AssociatedProduct':
      return {
        ...commonProps,
        seo: getProductSeo(product),
        associationType: product.associationType,
        items: product.items
          ? product.items?.map((item) =>
              fromServerToClientProduct(item, item?.catalogEntryTypeCode)
            )
          : product.sKUs?.map((sku) =>
              fromServerToClientProduct(sku, sku?.catalogEntryTypeCode)
            ),
      }
    default:
      return {
        ...commonProps,
        clusters:
          product.cluster?.map((clusterItem) =>
            fromServerToClientProduct(
              clusterItem,
              clusterItem?.catalogEntryTypeCode
            )
          ) || [],
        items: product.items
          ? product.items?.map((item) =>
              fromServerToClientProduct(item, item?.catalogEntryTypeCode)
            )
          : product.sKUs?.map((sku) =>
              fromServerToClientProduct(sku, sku?.catalogEntryTypeCode)
            ),
      }
  }
}

const getProductSeo = (product: ServerProduct) => {
  if (product.seo) return product.seo
  else {
    return product.sKUs ? product.sKUs?.[0]?.seo : product.items?.[0]?.seo
  }
}

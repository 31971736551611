import config from '@configs/config.base'
import 'react-lazy-load-image-component/src/effects/blur.css'

import {
  Attachment,
  PictureType,
  Product,
  ProductImageUsage,
} from '../../types/product'
import React, { CSSProperties, MouseEvent, TouchEvent, useMemo } from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import each from 'lodash/each'
import mockedData from './mocked-attachments'
import queryString from 'query-string'
import { useSite } from '../../foundation/hooks/useSite'
import { generateProductImagePath } from '@utils/product'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import { useTheme } from '@mui/material/styles'

export type ProductImageProps = Pick<
  React.DOMAttributes<HTMLImageElement>,
  'onLoad'
> & {
  alt?: string
  attachments?: Attachment[]
  attr?: object
  draggable?: boolean
  mpolicy?: string
  noImageStyle?: CSSProperties
  pictureType?: PictureType
  sequence?: string
  style?: any
  usage?: ProductImageUsage
  width?: number
  srcsetmap?: Record<number, string>
  onClick?: () => void
  onMouseMove?: (e: MouseEvent<HTMLImageElement>) => void
  onTouchMove?: (e: TouchEvent<HTMLImageElement>) => void
  onImageLoaded?: () => void
  backgroundColor?: string
  partialProduct?: Product
  isLazyLoadEnabled?: boolean
  isFramesProduct?: boolean
}

const ProductImage = ({
  alt,
  attachments,
  width = 600,
  pictureType,
  sequence,
  usage = 'PDP',
  attr,
  noImageStyle,
  draggable,
  srcsetmap,
  backgroundColor,
  partialProduct,
  onImageLoaded,
  isLazyLoadEnabled = true,
  isFramesProduct = false,
  ...rest
}: ProductImageProps) => {
  const { mySite: site } = useSite()
  const theme = useTheme()
  const isRetina = devicePixelRatio > 1
  const damDomain: string = site.xStoreCfg
    ? site.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain
  if (attachments === undefined && config.useMockedAttachments !== 'false') {
    attachments = mockedData.attachments
  }

  const url404 = config.PUBLIC_URL + '/images/common/404.svg'
  const imagePath = generateProductImagePath(
    damDomain,
    usage,
    partialProduct,
    pictureType,
    attachments,
    sequence,
    isFramesProduct
  )

  const getImageUrl = (width: number) => {
    return queryString.stringifyUrl({
      url: imagePath.toString(),
      query: {
        impolicy: 'GVI_resize',
        wid: isRetina ? width * 2 : width,
        bgc: backgroundColor || theme.palette.color.grey.lightest,
        ...attr,
      },
    })
  }

  const productImageSrcSets = (params: Record<number, string>): string => {
    let results = ''
    each(params, (resolution, imageWidth) => {
      const path = getImageUrl(Number(imageWidth) || width)
      results = results.concat(`${path} ${resolution}, `)
    })

    return results || ''
  }

  const altToSet = useMemo<string>(() => alt ?? imagePath, [alt, imagePath])

  return imagePath !== url404 && isLazyLoadEnabled ? (
    <LazyLoadImage
      alt={altToSet}
      src={getImageUrl(width)}
      effect="opacity"
      draggable={draggable}
      width="100%"
      srcSet={!!srcsetmap ? productImageSrcSets(srcsetmap) : ''}
      afterLoad={() => onImageLoaded && onImageLoaded()}
      {...rest}
    />
  ) : (
    <img
      alt={altToSet}
      src={getImageUrl(width)}
      width="100%"
      style={noImageStyle}
      draggable={draggable}
      onLoad={() => onImageLoaded && onImageLoaded()}
      {...rest}
    />
  )
}

export default ProductImage

import React from 'react'
import { Radio } from '@mui/material'
import { styled } from '@mui/material/styles'

interface CustomRadioProps {
  error?: boolean
  disabled?: boolean
}

const Icon = styled('span')(({ theme }) => ({
  borderRadius: 18,
  width: 16,
  height: 16,
  backgroundColor: 'transparent',
  borderWidth: 1,
  borderColor: theme.palette.text.dark,
  borderStyle: 'solid',
  'input:disabled ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.text.light,
    background: 'transparent',
  },
}))

const CheckedIcon = styled(Icon)<{
  disabled?: boolean
}>(({ theme, disabled }) => ({
  color: theme.palette.text.light,
  display: 'inherit',
  position: 'relative',
  'input:disabled ~ &': {
    boxShadow: 'none',
    borderColor: theme.palette.text.light,
    background: 'transparent',
  },

  '& > *': {
    position: 'absolute',
    height: '10px',
    width: '10px',
    right: '2px',
    top: '2px',
    borderRadius: '18px',
    background: disabled ? theme.palette.text.light : theme.palette.text.dark,
  },
}))

export function StyledRadio(props: CustomRadioProps) {
  const { disabled, ...other } = props
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={
        <CheckedIcon>
          <span></span>
        </CheckedIcon>
      }
      icon={<Icon />}
      disabled={disabled}
      {...other}
    />
  )
}

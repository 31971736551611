import {
  AccountEventData,
  CartData,
  CommonData,
  DataToSend,
  DeliveryData,
  ErrorForAnalytics,
  ErrorNetworkData,
  LoginData,
  NotFoundData,
  PaymentData,
  PDPData,
  PLPData,
  ProductUpdateData,
  SearchPageData,
  StoreSearchEventData,
  TYPData,
  VirtualPageData,
} from './interfaces'
import {
  TRACKER_EVENT,
  TRACKER_MINICART_OVERLAY_OPEN,
  TRACKER_PROMOCODE_TOGGLE,
  TRACKER_PRODS_CHANGE_UPC,
} from './constants/tracker'
import {
  formatProduct,
  getOrderPaymentType,
  getOrderState,
  getOrderTotalDiscount,
  getOrderZipCode,
  getProductsForAnalytics,
  getProductsForTYPAnalytics,
} from './formatters/productFormatter'

import { IOrderDetails } from '../../../types/order'
import Log from '../../../services/Log'
import { Product, ProductAnalyticsRX } from '../../../types/product'
import { ServerProduct } from '../../../types/product'
import debounce from 'lodash/debounce'
import { getFormattedError } from './formatters/formatError'
import md5 from 'crypto-js/md5.js'
import sha256 from 'crypto-js/sha256.js'
import SizeAdvisorUtil from '../../../utils/FrameGenius/SizeAdvisorUtil'
import { FrameGeniusContextType } from '@components/FrameGenius/FrameGeniusContext'
import { PAGE_TYPES } from './pageTypes'
import { removeRestrictedFields } from './utils'
import { RESTRICTED_COMMON_FIELDS_BY_EVENT } from './constants/restrictions'
import { IStoreSearchResult } from 'src/types/Tab/store'
import { ShippingsMethodsEnum } from 'src/types/cart'

export function sendAnalyticEventRaw<D extends { id: string }>(
  data: D,
  sessionId?: D
) {
  try {
    const tealiumPayload = sessionId
      ? {
          Session_Id: sessionId,
          ...data,
        }
      : data

    delete tealiumPayload['Error_QA']
    const payloadWithoutRestrictedFields = removeRestrictedFields(
      tealiumPayload,
      RESTRICTED_COMMON_FIELDS_BY_EVENT[data.id]
    )

    window.tealium_data2track &&
      window.tealium_data2track.push(payloadWithoutRestrictedFields)
  } catch (e) {
    Log.info(window.location.href, 'error')
  }
}

let sendAnalyticEvent = sendAnalyticEventRaw

window.addEventListener('tealiumInit', () => {
  sendAnalyticEvent = debounce(sendAnalyticEventRaw, 2000)
})

export const sendVirtualPageEvent = (data): void => {
  const dataToSend: VirtualPageData = {
    id: 'VirtualPage-View',
    ...data,
  }
  sendAnalyticEvent<VirtualPageData>(dataToSend)
}

export const sendLoadingReadyEvent = (
  pageType: string,
  pageSection: string
): void => {
  // see tealium documentation, this event overwrites
  // the first page load event and aborts previous events
  // TODO refactor to use sendAnalitycEvent
  Log.info(
    `ANALYTICS - send page ready pagetype: ${pageType} pageSection: ${pageSection}`
  )
  let obj
  try {
    obj = {
      id: 'Loading-Ready',
      Page_Type: pageType, // include data object properties listed in Data Layer
      Page_Section_1: pageSection,
    }
  } catch (err) {
    obj = {
      id: 'Loading-Error',
      Error_Source: 'Server',
      Error_Code: 'utag_data object',
      Error_Detail: 'Error setting ',
    }
  } finally {
    window.tealium_data2track && window.tealium_data2track.push(obj)
  }
  Log.info('ANALYTICS - Loading Ready Event Sent')
}

export const sendServerErrorEvent = (error: ErrorNetworkData): void => {
  const dataToSend: ErrorForAnalytics = getFormattedError(error)
  sendAnalyticEvent<ErrorForAnalytics>(dataToSend)
}

type NotFoundErrorData = {
  loginStatus: boolean
  requestedUrl: string
}
export const sendNotFoundErrorEvent = ({
  loginStatus,
  requestedUrl,
}: NotFoundErrorData) => {
  const dataToSend: NotFoundData = {
    id: 'Error',
    User_LoginStatus: loginStatus ? 'Logged' : 'Guest',
    Page_Type: 'Error',
    Page_Section1: 'Other',
    Page_Section2: 'ErrorHttp404',
    User_LoginType: 'Standard',
    Error_Source: '404',
    Error_Code: '404 - page not found',
    Error_Details: requestedUrl,
  }
  sendAnalyticEventRaw(dataToSend)
}

export const sendLoginEvent = (email, commonData: CommonData) => {
  const dataToSend: LoginData = {
    ...commonData,
    id: TRACKER_EVENT,
    User_LoginType: 'Standard',
    User_Email_MD5: email ? md5(email?.toLowerCase()).toString() : undefined,
    User_Email_SHA256: email
      ? sha256(email?.toLowerCase()).toString()
      : undefined,
    Events_UserLogin: '1',
  }

  sendAnalyticEventRaw(dataToSend)
}

type AccountEventPayload = {
  common: CommonData
  email?: string
  userId?: string
}
export const sendMyAccountEvent = ({
  common,
  email,
  userId,
}: AccountEventPayload) => {
  const dataToSend: AccountEventData = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Static',
    Page_Section1: 'Account',
    User_LoginStatus: 'Logged',
    User_LoginType: 'Standard',
    User_Email_MD5: md5(email).toString(),
    User_Email_SHA256: sha256(email).toString(),
    User_EmailOptin: '0',
    User_Id: userId,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendLoginPageEvent = (common: CommonData) => {
  const dataToSend: DataToSend = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Login&Register',
    Page_Section1: 'Account',
    Page_Design: 'Login&Register',
    User_LoginStatus: 'Guest',
    User_LoginType: 'Standard',
  }
  sendAnalyticEventRaw(dataToSend)
}

export const sendPdpEvent = (data: {
  common: CommonData
  loginStatus: boolean
  products: ServerProduct[]
  pageSection1: string
  pageSection2: string
  Vm_IsUpcSupported: boolean
  Vm_IsBrowserSupported: boolean
}): void => {
  const Products = getProductsForAnalytics(data.products, PAGE_TYPES.PDP)

  const dataToSend: PDPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: PAGE_TYPES.PDP,
    Page_Section1: data.pageSection1,
    Page_Section2: data.pageSection2,
    Vm_IsUpcSupported: data.Vm_IsUpcSupported ? '1' : '0',
    Vm_IsBrowserSupported: data.Vm_IsBrowserSupported ? '1' : '0',
    Products,
  }
  sendAnalyticEventRaw(dataToSend)
}

// TODO product got from addToCartEvent don't have prices
export const sendAddToCartEvent = (products: ServerProduct[]) => {
  const Products = getProductsForAnalytics(products)
  const dataToSend = {
    id: TRACKER_EVENT,
    Events_CartAdd: '1',
    Products,
  }
  sendAnalyticEventRaw(dataToSend)
}

export const sendClickEvent = <T extends object>(data?: T) => {
  const dataToSend: DataToSend = {
    id: 'Click',
    ...data,
  }
  sendAnalyticEventRaw(dataToSend)
}

export const sendCountrySelectionEvent = (countryCode: string) => {
  sendClickEvent({ countryCode })
}

export const sendCartEvent = (data: {
  common?: Partial<CommonData>
  loginStatus: boolean
  products: ProductAnalyticsRX[]
}): void => {
  const Products = getProductsForAnalytics(data.products)
  const dataToSend: CartData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'CartPage',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Page_Section1: 'CartPage',
    Products,
  }
  sendAnalyticEvent(dataToSend)
}

interface CouponSubmitPayload extends Partial<CommonData> {
  result: boolean
  Error_Source?: string
  Error_Code?: string
  Error_Message?: string
}

export const sendCouponSubmitEvent = ({
  result,
  Order_DiscountCode,
  Order_DiscountName,
  Error_Code,
  Error_Message,
  Error_Source,
}: CouponSubmitPayload): void => {
  const dataToSend: DataToSend = {
    id: result ? 'OrderDiscountCode-Applied' : 'OrderDiscountCode-Tentative',
    Order_DiscountCode,
    Order_DiscountName,
    Error_Code,
    Error_Message,
    Error_Source,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendDeliveryEvent = (data: {
  common?: Partial<CommonData>
  loginStatus: boolean
  products: ServerProduct[]
  shipMode?: string
}): void => {
  const Products = getProductsForAnalytics(data.products)
  const dataToSend: DeliveryData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Delivery',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Page_Section1: 'Checkout',
    Page_Section2: 'Standard',
    Products,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendTYPData = (data: {
  common?: Partial<CommonData>
  loginStatus: boolean
  products: ServerProduct[]
  email?: string
  orderDetails: IOrderDetails
  shipMode?: string
}): void => {
  const { orderItem } = data.orderDetails
  const Products = getProductsForTYPAnalytics(data.products, orderItem)
  const orderDiscountAmount = getOrderTotalDiscount(data.orderDetails)
  const orderShippingMode =
    orderItem[0].shipModeCode === ShippingsMethodsEnum.ShipToStore
      ? 'Pick-up-point'
      : orderItem[0].shipModeCode

  const dataToSend: TYPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Thankyou',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Page_Section1: 'Checkout',
    Page_Section2: 'Standard',
    Products,
    User_Email_MD5: md5(data.email).toString(),
    User_Email_SHA256: sha256(data.email).toString(),
    User_EmailOptin: '0',
    Order_Currency: data.orderDetails.grandTotalCurrency,
    Order_Id: data.orderDetails?.orderId,
    Order_ProductsAmount: data.orderDetails.totalProductPrice,
    Order_DiscountAmount: (+orderDiscountAmount || 0).toFixed(2),
    Order_ShippingAmount: data.orderDetails.totalShippingCharge,
    Order_ShippingTaxRate: data.orderDetails.totalShippingTax,
    Order_TaxAmount: data.orderDetails.totalSalesTax,
    Order_ShippingMode: orderShippingMode,
    Order_ShippingDiscount: Number(
      data.orderDetails?.adjustment?.find(
        (a) => a.usage === 'Shipping Adjustment'
      )?.amount || '0'
    ).toFixed(2),
    Order_PaymentType: getOrderPaymentType(data.orderDetails),
    Order_State: getOrderState(data.orderDetails),
    Order_ZipCode: getOrderZipCode(data.orderDetails),
    Order_InsuranceAmount: '',
    Order_InsuranceCode: '',
    Order_InsuranceBenefits: '',
    Order_PaymentInstallments: '',
  }
  sendAnalyticEvent(dataToSend)
}

export const sendPaymentEvent = (data: {
  common?: Partial<CommonData>
  loginStatus?: boolean
  products: ServerProduct[]
  email?: string
  isOptin?: boolean
  shipMode?: string
}): void => {
  const Products = getProductsForAnalytics(data.products, PAGE_TYPES.PAYMENT)
  const dataToSend: PaymentData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Payment',
    User_LoginType: 'Standard',
    Page_Section1: 'Checkout',
    Page_Section2: 'Standard',
    Order_ShippingMode:
      data.shipMode === ShippingsMethodsEnum.ShipToStore
        ? 'Pick-up-point'
        : data.shipMode,
    User_Email_MD5: md5(data.email).toString(),
    User_Email_SHA256: sha256(data.email).toString(),
    User_EmailOptin: !!data.isOptin ? '1' : '0',
    Products,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendAccountInformationEvent = (
  data: {
    common?: Partial<CommonData>
    Page_Type?: string
    Page_Section1?: string
    Page_Section2?: string
  } = {}
) => {
  const {
    common,
    Page_Type = 'Static',
    Page_Section1 = 'Account',
    ...rest
  } = data

  const dataToSend = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type,
    Page_Section1,
    ...rest,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendProductUpdateEvent = (product, quantity?: number): void => {
  const dataToSend: ProductUpdateData = {
    id: TRACKER_PRODS_CHANGE_UPC,
    Products: {
      [product.id]: formatProduct(product, quantity),
    },
  }

  sendAnalyticEventRaw(dataToSend)
}

export const sendProductRemoveEvent = (product: ServerProduct): void => {
  const id = 'Prods-Delete'
  const Products = getProductsForAnalytics([product], undefined, id)
  sendAnalyticEventRaw({
    id,
    Products,
  })
}

export const sendHomeEvent = (common?: Partial<CommonData>): void => {
  const dataToSend: DataToSend = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Home',
    Page_Section1: 'Home',
  }
  sendAnalyticEvent(dataToSend)
}

export const sendStaticEvent = (data: {
  common?: Partial<CommonData>
  page_Section1: string
  page_Section2: string
}): void => {
  const dataToSend: DataToSend = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Static',
    Page_Section1: data.page_Section1,
    Page_Section2: data.page_Section2,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendLandingEvent = (data: {
  common?: Partial<CommonData>
  page_Section1: string
  page_Section2: string
}): void => {
  const dataToSend: DataToSend = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Landing',
    Page_Section1: data.page_Section1,
    Page_Section2: data.page_Section2,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendPlpEventOld = (data: {
  common?: Partial<CommonData>
  qnt: number
  products: Product[]
  pageSection: string
}): void => {
  const Products = getProductsForAnalytics(data.products as any)
  const dataToSend: PLPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Plp',
    Page_Section1: data.pageSection,
    Search_ResultItemsQnt: `${data.qnt}`,
    Search_FacetValues_String: '',
    Page_Design: 'Editorial',
    Products,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendSearchResultEvent = (data: {
  common?: Partial<CommonData>
  qnt: number
  products: Product[]
  pageSection: string
  searchKeyword: string
  searchKeyActual: string
}): void => {
  const Products = getProductsForAnalytics(data.products as any)
  const dataToSend: SearchPageData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Search',
    Page_Section1: 'Search',
    Search_ResultItemsQnt: `${data.qnt}`,
    Page_Design: 'Tiles&Pages',
    Products,
    Search_Keyword: data.searchKeyword,
    Search_KeyActual: data.searchKeyActual,
    Search_Type: 'text',
    Search_View: 'SERP',
    Events_SearchRun: '1',
  }
  sendAnalyticEvent(dataToSend)
}

export const getFrameAdvisorAnalyticsData = (
  frameGeniusData?: FrameGeniusContextType
) => {
  let formattedData = ''
  frameGeniusData &&
    Object.values(frameGeniusData.analysisResults).map((v, i) => {
      if (typeof v === 'object' && !Array.isArray(v)) return

      return (formattedData += `${
        Object.keys(frameGeniusData.analysisResults)[i]
      }=${v},`)
    })

  return {
    Page_UserFace: formattedData, // FaceShape=, FaceLength=, Age=, HairColor=, EyeColor= E.g. "FaceShape=Angular,FaceLength=Oval
    Page_UserStyle: '',
  }
}

export const sendFilterSelectedEvent = (
  filters: {
    [key: string]: { value: string; facetName: string }
  },
  count?: number,
  frameGeniusData?: FrameGeniusContextType
): void => {
  Log.info('ANALYTICS - send filter selected event', filters as any)
  const isFrameGeniusToggleEnabled =
    SizeAdvisorUtil.getSizeAdvisorPlpToggleStatus()

  const frameAdvisorData = getFrameAdvisorAnalyticsData(frameGeniusData)

  let facetsValue = ''

  Object.values(filters).forEach((v, i, array) => {
    if (v.value) {
      facetsValue += `${v.facetName}=${v.value}`
      if (i !== array.length - 1) {
        facetsValue += '|'
      }
    }
  })

  let dataToSend = {
    ...frameAdvisorData,
    id: TRACKER_EVENT,
    Search_FacetValues_String: facetsValue,
    Search_ResultItemsQnt: count?.toString(),
    Events_SearchFiltering: '1',
    Search_FacetValues_Type: !!isFrameGeniusToggleEnabled ? 'framegenius' : '', // standard, framegenius
  }

  sendAnalyticEvent(dataToSend)
}

export const sendNewsletterSubscriptionEvent = (email: string): void => {
  Log.info('ANALYTICS - send newsletter subscription event', email)
  let dataToSend = {
    id: TRACKER_EVENT,
    User_Email_MD5: email ? md5(email?.toLowerCase()).toString() : undefined,
    User_Email_SHA256: email
      ? sha256(email?.toLowerCase()).toString()
      : undefined,
    User_EmailOptin: '1',
    Events_UserEmailSub: '1',
    Events_ProdViewEcomOosEmailClick: '0',
  }
  sendAnalyticEventRaw(dataToSend)
}

export const sendRegistrationEvent = (
  email: string,
  commonData: CommonData,
  acceptedNewsletterFlag?: boolean
): void => {
  Log.info('ANALYTICS - send registration event', email)
  const acceptedNewsletter = acceptedNewsletterFlag ? '1' : '0'
  let dataToSend = {
    ...commonData,
    id: TRACKER_EVENT,
    User_LoginType: 'Standard',
    User_AccountNew: '1',
    Events_UserEmailSub: acceptedNewsletter,
    Events_UserAccountNew: '1',
    User_Email_MD5: email ? md5(email.toLowerCase()).toString() : undefined,
    User_Email_SHA256: email
      ? sha256(email.toLowerCase()).toString()
      : undefined,
  }

  sendAnalyticEventRaw(dataToSend)
}

export const sendSearchEvent = (searchingString: string, qty: number): void => {
  Log.info('ANALYTICS - searching event', searchingString)
  let dataToSend = {
    id: TRACKER_EVENT,
    Events_SearchRun: '1',
    Search_ResultItemsQnt: qty.toString(),
    Search_Keyword: searchingString,
    Search_Type: 'text',
    Search_View: 'NO-SERP',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendPromoCodeEvent = (): void => {
  Log.info('ANALYTICS - promoCode event')
  let dataToSend = {
    id: TRACKER_PROMOCODE_TOGGLE,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendMiniCartOverlayOpenEvent = () => {
  sendAnalyticEvent({
    id: TRACKER_MINICART_OVERLAY_OPEN,
  })
}

export const sendAddToFavouritesEvent = (product: ServerProduct) => {
  sendAnalyticEventRaw({
    id: 'ProdFavAdd',
    Products: getProductsForAnalytics([product], PAGE_TYPES.PDP),
  })
}

export const sendStoreLocatorPageEvent = (common: Partial<CommonData>) => {
  sendAnalyticEvent({
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'FindStoreExam',
    Page_Section1: 'Exam',
  })
}

export const sendSearchStoreEvent = (result: IStoreSearchResult) => {
  if (!result.place) {
    return
  }
  const { address, zipCode, isGeolocationSearch } = result.place
  sendAnalyticEventRaw<StoreSearchEventData>({
    id: 'Event',
    Store_Search_Keyword: isGeolocationSearch ? zipCode : address,
    Store_Search_Type: isGeolocationSearch ? 'geolocalized' : 'text',
    Store_Search_ResultItemsQnt: result.stores.length.toString(),
    Events_FunnelStoreSearch: '1',
  })
}

export const sendStoreDetailsEvent = (
  storeName: string,
  common: Partial<CommonData>
) => {
  sendAnalyticEventRaw({
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Static',
    Page_Section1: 'Locator',
    Page_Section2: storeName,
  })
}
